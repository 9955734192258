import React, {HTMLProps, PropsWithChildren} from 'react';
import Sidebar from '../Sidebar/Sidebar.tsx';

import './Page.css';
import classNames from "classnames";

interface PageProps {
  className?: string;
  header?: React.ReactNode;
}

const Page = ({ className, header, children }: PropsWithChildren<PageProps>) => {
  return (
    <div className={`page-container ${className ?? ""}`}>
      <Sidebar/>
      <div className="page-content-wrapper">
        {header}
        {children}
      </div>
    </div>
  )
};

type PageWrapperProps = Pick<HTMLProps<'div'>, 'className'>;


Page.Wrapper = ({ children, className }: PropsWithChildren<PageWrapperProps>) => {
  return (
    <div className={classNames("page-wrapper", className)} >
      {children}
    </div>
  );
};

export default Page;

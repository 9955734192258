import {ReactNode} from "react";
import {Card} from "antd";
import BarScoreChart from "./HelperCharts/BarScoreChart.tsx";
import {NPSResult} from "../resultTypes.ts";

interface NPSChartProps {
    results: NPSResult[]
}

function NPSChart({
                      results
                  }: NPSChartProps): ReactNode {

    const NPSMap: Record<number, {value: number, color: string}> = {
        0: {value: 0, color: 'hsl(0, 70%, 50%)'},     // Red tone for detractors
        1: {value: 0, color: 'hsl(10, 70%, 50%)'},
        2: {value: 0, color: 'hsl(20, 70%, 50%)'},
        3: {value: 0, color: 'hsl(30, 70%, 50%)'},
        4: {value: 0, color: 'hsl(40, 70%, 50%)'},  // Neutral tone for neutrals
        5: {value: 0, color: 'hsl(50, 70%, 50%)'},
        6: {value: 0, color: 'hsl(60, 70%, 50%)'},
        7: {value: 0, color: 'hsl(70, 70%, 50%)'},
        8: {value: 0, color: 'hsl(80, 70%, 50%)'},
        9: {value: 0, color: 'hsl(120, 70%, 50%)'}, // Green tone for promoters
        10: {value: 0, color: 'hsl(130, 70%, 50%)'}
    }

    results?.forEach(r => {
            NPSMap[r.score].value += 1;
    })

    const NPSResults = ((Object.keys(NPSMap) as unknown as number[]).map(k => ({
        score: k.toString(),
        value: NPSMap[k].value,
        color: NPSMap[k].color
    })));

    return <Card
        className="h-full overflow-hidden"
        title={"Nps"}
    >
        <BarScoreChart
            data={NPSResults}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'NPS Score',
                legendPosition: 'middle',
                legendOffset: 32
            }}

        />
    </Card>
}

export default NPSChart

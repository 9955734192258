import { useParams } from 'react-router-dom';

export function useParam<ParamType = string>(
  name: string,
  validator?: (value: string | undefined) => ParamType | undefined,
): ParamType | undefined {
  const params = useParams();
  return validator?.(params[name]) ?? params[name] as ParamType;
}

export function idValidator(value: string | undefined): number | undefined {
  if (value == null) return;
  const id = parseInt(value, 10);
  return id > 0 ? id : undefined;
}

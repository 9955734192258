import {Alert, Card, Collapse, message} from 'antd';
import {Assistant, AssistantStatus} from "../../../../gql/graphql";
import {useUpdateAssistantMutation} from "../../../../api/assistant.api.ts";
import {
  AimOutlined,
  BarsOutlined,
  FormatPainterOutlined,
  SnippetsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {PropsWithChildren, ReactNode, useCallback} from "react";
import ConfigureFlow from "./ConfigureFlow.tsx";
import ConfigureGoal from "./ConfigureGoal.tsx";
import ConfigureStyle from "./ConfigureStyle.tsx";
import ConfigureIdentity from "./ConfigureIdentity.tsx";
import {AssistantInfo} from "../assistantTypes.ts";
import ConfigureContext from "./ConfigureContext.tsx";
import {ChatPreview} from "./ChatPreview.tsx";

interface ConfigureAssistantProps {
  assistant: Pick<Assistant, 'id' | 'identifier' | 'status' | 'info'>;
}

interface SectionLabelProps {
  icon: ReactNode;
}

const SectionLabel = ({ icon, children }: PropsWithChildren<SectionLabelProps>) => (
  <div className="flex items-center text-base font-bold">
    <span className="text-xl mr-2 text-primary">{icon}</span>
    {children}
  </div>
);

const ConfigureAssistant = ({ assistant }: ConfigureAssistantProps) => {
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 1,
  });

  const [updateAssistant] = useUpdateAssistantMutation({
    context: { debounceKey: `updateAssistant(${assistant.id}` },
    onError: () => messageApi.error('Failed to update assistant'),
  });

  const updateAssistantInfo = useCallback(async (info: Partial<AssistantInfo>) => {
    if (Object.keys(info).length === 0) return;
    await updateAssistant({
      variables: {
        id: assistant.id,
        patch: {
          info: {
            ...(assistant.info as AssistantInfo | undefined ?? {}),
            ...info,
          },
        },
      },
      optimisticResponse: {
        updateAssistant: {
          assistant: {
            id: assistant.id,
            identifier: assistant.identifier,
            status: assistant.status,
            info: {
              ...(assistant.info as AssistantInfo | undefined ?? {}),
              ...info,
            },
            __typename: 'Assistant',
          },
        },
      },
    });
  }, [assistant, updateAssistant]);

  const editable = assistant.status === AssistantStatus.Draft;

  return (
    <div className="flex-1 flex gap-4 overflow-scroll">
      {contextHolder}
      <Card
        className="flex-1 min-w-[400px] max-h-full self-start overflow-scroll"
      >
        {!editable && (<Alert
          className="mb-[12px]"
          message="You can't edit an activated assistant."
        />)}
        <Collapse
          className="mx-[-16px] my-[-12px] [&>.ant-collapse-item>.ant-collapse-content]:pb-4"
          defaultActiveKey={['goal', 'identity', 'flow']}
          expandIconPosition="end"
          ghost
          items={[
            {
              key: 'goal',
              label: <SectionLabel icon={<AimOutlined />}>Goal</SectionLabel>,
              children: <ConfigureGoal assistant={assistant} updateAssistantInfo={updateAssistantInfo} editable={editable} />,
            },
            {
              key: 'identity',
              label: <SectionLabel icon={<UserOutlined />}>Identity</SectionLabel>,
              children: <ConfigureIdentity assistant={assistant} updateAssistantInfo={updateAssistantInfo} editable={editable} />,
            },
            {
              key: 'flow',
              label: <SectionLabel icon={<BarsOutlined />}>Flow</SectionLabel>,
              children: <ConfigureFlow assistant={assistant} updateAssistantInfo={updateAssistantInfo} editable={editable} />,
            },
            {
              key: 'context',
              label: <SectionLabel icon={<SnippetsOutlined />}>Context</SectionLabel>,
              children: <ConfigureContext assistant={assistant} updateAssistantInfo={updateAssistantInfo} editable={editable} />,
            },
            {
              key: 'style',
              label: <SectionLabel icon={<FormatPainterOutlined />}>Style</SectionLabel>,
              children: <ConfigureStyle assistant={assistant} updateAssistantInfo={updateAssistantInfo} editable={editable} />,
            },
          ]}
        />
      </Card>
      <ChatPreview assistant={assistant} disabled={!editable} />
    </div>
  );
};

export default ConfigureAssistant;

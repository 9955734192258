import {ReactNode, useEffect} from "react";
import { useState} from "react";
import type { SegmentedProps } from "antd";
import {Segmented } from "antd";
import GetStartedButton from "./GetStartedButton.tsx";

interface Segment {
  name: string;
  description: string;
  content: ReactNode;
  icon?: React.ComponentType<{ className?: string | undefined }>;
}

interface SegmentedSectionProps {
  id?: string;
  subtitle: ReactNode;
  title: ReactNode;
  description: ReactNode;
  segments: Segment[];
}

export default function SegmentedSection({ id, subtitle, title, description, segments }: SegmentedSectionProps) {
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0);
  const selectedSegment = segments[selectedSegmentIndex];

  const segmentOptions: SegmentedProps<number>["options"] = segments.map((useCase, index) => ({
    label: useCase.name,
    value: index,
    icon: useCase.icon && <useCase.icon />,
  }));
  
  // Cycle through the segments every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedSegmentIndex((selectedSegmentIndex + 1) % segments.length);
    }, 8000);
    return () => { clearInterval(interval); };
  }, [selectedSegmentIndex, segments.length]);

  return (
    <div className="bg-white sm:px-8 py-24 sm:py-24" id={id}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:text-center lg:max-w-2xl">
          <h2 className="text-base font-semibold leading-7 text-primary">{subtitle}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {description}
          </p>
        </div>
        <div className="mx-auto mt-8 sm:mt-10 lg:mt-12 lg:max-w-4xl">
          <Segmented
            block
            onChange={setSelectedSegmentIndex}
            options={segmentOptions}
            size="large"
            value={selectedSegmentIndex}
          />
          <div className="relative text-center bg-gray-100 rounded-xl p-8 mt-2 mb-4">
            {selectedSegment.content}
          </div>
          <div className="p-8 font-semibold text-center">
            Want to give it a try?
            <GetStartedButton className="mx-4">Sign up</GetStartedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

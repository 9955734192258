import {useCallback, useState} from "react";
import {Button, Input} from "antd";
import {SendOutlined} from "@ant-design/icons";

import './InputMessage.css';

interface InputMessageProps {
  quickResponses?: string[];
  onSend: (message: string) => void;
}

const InputMessage = ({quickResponses, onSend}: InputMessageProps) => {
  const [message, setMessage] = useState('');

  const sendCallback = useCallback((messageToSend: string) => {
    if (messageToSend === '') return;
    onSend(messageToSend);
    setMessage('');
  }, [onSend]);

  return (
    <div className="input-message-wrapper">
      <div className="quick-responses">
        {quickResponses?.map((response, index) => (
          <Button
            key={index}
            type="dashed"
            onClick={() => {
              sendCallback(response);
            }}
          >
            {response}
          </Button>
        ))}
      </div>
      <div className="input-message">
        <Input.TextArea
          autoFocus
          autoSize={{minRows: 1, maxRows: 6}}
          bordered={false}
          onChange={(e) => { setMessage(e.target.value) }}
          onPressEnter={async (e) => {
            // Prevent typing a new line if shift isn't pressed
            if (!e.shiftKey) {
              e.preventDefault();
              sendCallback(message);
            }
          }}
          placeholder="Type a message..."
          value={message}
        />
        <SendOutlined
          className="send-button"
          onClick={() => sendCallback(message)}
          rotate={-90}
        />
      </div>
      <span className="help">Press <kbd>Enter</kbd> to send or <kbd>Shift</kbd> + <kbd>Enter</kbd> for a newline</span>
    </div>
  )
};

export default InputMessage;

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface QuestionTooltipProps {
    textToShow: string
}

const QuestionTooltip = ({textToShow}: QuestionTooltipProps) => <Tooltip
  title={textToShow}
>
  <QuestionCircleOutlined/>
</Tooltip>

export default QuestionTooltip;
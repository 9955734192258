import classNames from "classnames";

export interface HighlightProps {
  name: string;
  description: string;
  icon?: React.ComponentType<{ className: string | undefined }>;
  className?: string;
}

export function Highlight(props: HighlightProps) {
  const { name, description, className } = props;
  return (
    <div className={classNames("relative text-center rounded-xl p-8", className)}>
      <dt className="text-base font-semibold leading-7 text-gray-900">
        <div className="m-auto mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-primary">
          {props.icon ?
            <props.icon className="h-5 w-5 text-white" aria-hidden="true" /> :
            <span className="text-white" aria-hidden="true">1</span>}
        </div>
        {name}
      </dt>
      <dd className="ms-0 mt-2 text-base leading-7 text-gray-600">{description}</dd>
    </div>
  )

}

export interface HighlightsProps {
  highlights: HighlightProps[];
  highlightClassName?: string;
}

export default function Highlights({ highlights, highlightClassName }: HighlightsProps) {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-3 lg:gap-y-16">
      {highlights.map((highlight) => (
        <Highlight key={highlight.name} className={highlightClassName} {...highlight} />
      ))}
    </dl>
  );
}
import {Button, Popconfirm} from "antd";
import {CheckOutlined, QuestionOutlined} from "@ant-design/icons";
import {useState} from "react";
import MessageList from "../../components/chat/MessageList.tsx";
import InputMessage from "../../components/chat/InputMessage.tsx";
import HelpModal from "../../HelpModal.tsx";
import type {Chat} from "../../gql/graphql.ts";

import "./ChatUIGeneral.css";

interface ChatUIGeneralProps {
  name: string,
  messages: Chat['messages'],
  isAssistantMessageLoading: boolean,
  onSendMessageCallback: (message: string) => void,
  isFinishChatLoading: boolean,
  onFinishCallback?: () => void
}

function ChatUIGeneral({
                         name,
                         messages,
                         isAssistantMessageLoading,
                         onSendMessageCallback,
                         isFinishChatLoading,
                         onFinishCallback
                       }: ChatUIGeneralProps) {

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isFinishPopconfirmOpen, setIsFinishPopconfirmOpen] = useState(false);

  const quickRespsonses = messages[messages.length - 1]?.quick_responses as string[];

  return (
    <>
      <h1>{name}</h1>
      <MessageList
        messages={messages}
        typing={isAssistantMessageLoading}
      />
      <InputMessage
        onSend={(message: string) => {
          if (message === '') return;
          onSendMessageCallback(message);
        }}
        quickResponses={quickRespsonses}
      />
      {onFinishCallback !== undefined && <div className="controls">
        <Button
          icon={<QuestionOutlined/>}
          onClick={() => { setIsHelpModalOpen(true); }}
        >
          Help
        </Button>
        <Popconfirm
          cancelText="No"
          icon={null}
          okButtonProps={{
            loading: isFinishChatLoading,
          }}
          okText="Yes"
          onConfirm={async () => {
            onFinishCallback();
          }}
          open={isFinishPopconfirmOpen}
          title="Are you sure you want to finish this chat?"
        >
          <Button
            icon={<CheckOutlined/>}
            onClick={() => { setIsFinishPopconfirmOpen(true); }}
            type="primary"
          >
            Finish chat
          </Button>
        </Popconfirm>
      </div>}
      <HelpModal
        onClose={() => { setIsHelpModalOpen(false); }}
        open={isHelpModalOpen}
      />
    </>
  )
}

export default ChatUIGeneral;

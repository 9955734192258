import {ResponsivePie} from "@nivo/pie";

interface Datum {
    id: string,
    label: string,
    value: number,
    color: string,
}

interface PieChartProps {
    data: Datum[];
}

const PieChart = ({data}: PieChartProps) => {
    return (
        <div className="h-[400px]">
            <ResponsivePie
                data={data}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                innerRadius={0.5}
                colors={{ datum: 'data.color' }}
            />
        </div>
    );
}

export default PieChart

import {Message} from "types";
import {theme} from "antd"
import Spinner from '../Spinner/Spinner.tsx';

import './MessageList.css';

interface Props {
  messages: Message[];
  typing: boolean;
}

const {useToken} = theme;

const MessageList = ({messages, typing}: Props) => {
  const {token} = useToken();

  return (
    <div className="message-list">
      {typing && (
        <span className="message assistant">
          <Spinner />
        </span>
      )}
      {messages
        .filter((message) => message.role !== 'system')
        // We reverse the list, because we're using flex-direction: column-reverse to allow the last messages
        // to be at the bottom of the screen.
        .reverse().map((message, i) => (
          <span key={i} className={`message ${message.role}`} style={{backgroundColor: (message.role === "user" && token.colorPrimary) ? token.colorPrimary : undefined}}>
            {message.content}
          </span>
        ))}
    </div>
  )
};

export default MessageList;

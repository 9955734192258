import {Tag} from 'antd';
import {AssistantStatus} from "../../gql/graphql.ts";
import {HTMLProps} from "react";

interface AssistantStatusTagProps extends Pick<HTMLProps<'div'>, "className"> {
  status: AssistantStatus;
}

const STATUS_COLORS: Record<AssistantStatus, string> = {
  DRAFT: 'black',
  ACTIVE: 'blue',
};

const AssistantStatusTag = ({ status, ...otherProps }: AssistantStatusTagProps) => {
  return (
    <Tag
      color={STATUS_COLORS[status]}
      {...otherProps}
    >
      {status}
    </Tag>
  );
};

export default AssistantStatusTag;

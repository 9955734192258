import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

interface SpinnerProps {
  size?: number;
}

const Spinner = ({ size = 20 }: SpinnerProps) => (
  <Spin indicator={<LoadingOutlined style={{fontSize: size}} spin/>}/>
);

export default Spinner;

import {AssistantInfo, StepType} from "../../../pages/assistants/assistant/assistantTypes.ts";

export interface AssistantTemplate {
  name: string;
  description: string;
  assistantInfo: Partial<AssistantInfo>;
}

export const ASSISTANT_TEMPLATES: AssistantTemplate[] = [
  {
    name: 'Qualify Inbound Leads',
    description: 'Qualify leads by asking for background, interests and contact information.',
    assistantInfo: {
      name: 'Sara',
      role: 'Sales',
      goal: 'Qualify leads by asking for background, interests and contact information.',
      welcomeMessage: 'Hi there! I\'m Sara. I\'d love to learn more about you and how we can help. Would you mind answering a few questions for me?',
      welcomeButtonText: 'Let\'s go!',
      steps: [
        {
          title: 'Introduce the company',
          goal: 'Introduce the company, summarize what we do and ask for the respondent\'s name.',
          type: StepType.Conversation,
        },
        {
          title: 'Find out what they do',
          goal: 'Ask what the respondent does for a living.',
          type: StepType.Conversation,
        },
        {
          title: 'Figure out interests',
          goal: 'Ask what products or services the respondent is interested in.',
          type: StepType.MultipleChoice,
          options: [
            'Survey Assistant',
            'Employee Training',
            'Not sure yet',
          ],
        },
        {
          title: 'Highlight benefits + questions',
          goal: 'Explain the benefits of the products or services the respondent is interested in and answer any questions they may have.',
          type: StepType.Conversation,
        },
        {
          title: 'Ask email',
          goal: 'Ask for the respondent\'s email address, so we can contact them for next steps.',
          type: StepType.Conversation,
        },
      ],
      endMessage: 'Thank you for your time! We will be in touch soon.',
      endButtonText: 'Check out our website',
      endButtonUrl: 'https://www.querii.org',
      context: "We're Querii, a company that helps businesses generate leads and improve customer satisfaction by providing them with AI assistants that replace traditional forms with conversational surveys.",
    },
  },
  {
    name: 'Gather Feedback',
    description: 'Find out how your customers feel about your product.',
    assistantInfo: {
      name: 'Annie',
      role: 'Customer Success',
      goal: 'Find out how customers feel about the product and why they feel that way.',
      welcomeMessage: 'Hi there! I\'m Annie. I\'d love to learn more about your experience with our product. Would you mind answering a few questions for me?',
      welcomeButtonText: 'Let\'s go!',
      steps: [
        {
          title: 'Ask NPS & Why',
          goal: 'Ask for an NPS score and figure out why they gave that score.',
          type: StepType.NPS,
          ask_why: true,
        },
      ],
      endMessage: 'Thank you for your feedback! Your opinion is important to us.',
    },
  },
  {
    name: 'Pre-screen Job Applicants',
    description: 'Pre-screen job applicants by asking for their background and experience.',
    assistantInfo: {
      name: 'Alex',
      role: 'HR',
      goal: 'Pre-screen job applicants for a Sales role.',
      welcomeMessage: "Hi there! I'm Alex. I'm here to help you apply for a Sales role at Querii. Would you mind answering a few questions for me?",
      welcomeButtonText: "Let's go!",
      steps: [
        {
          title: 'Introduce the company',
          goal: "Introduce the company, summarize what we do and ask for the respondent's name.",
          type: StepType.Conversation,
        },
        {
          title: 'Introduce the role',
          goal: "Make sure the respondent knows what role they're applying for and what it entails.",
          type: StepType.Conversation,
        },
        {
          title: 'Validate experience',
          goal: "Ask about the respondent's experience and qualifications.",
          type: StepType.Conversation,
        },
        {
          title: 'Q&A',
          goal: "Answer any questions the respondent may have about the role or the company. Once they're ready, let them know we'll be in touch soon with next steps.",
          type: StepType.Conversation,
        },
      ],
      endMessage: 'Thank you for your time! We will review your application and get back to you soon.',
      context: "We're Querii, a company that helps businesses generate leads and improve customer satisfaction by providing them with AI assistants that replace traditional forms with conversational surveys.",
    },
  }
];
import {ReactNode, useState} from "react";
import {Chat} from "types";
import {Card, Empty} from "antd";
import ChatStatusTag from "../../../../components/chat/ChatStatusTag.tsx";
import {ChatStatus} from "../../../../gql/graphql.ts";
import ChatMessagesCard from "../../../../components/ChatMessagesCard/ChatMessagesCard.tsx";

interface AssistantResultChatsProps {
    chats: Chat[]
}

function AssistantResultChats({
                                  chats
                              }: AssistantResultChatsProps): ReactNode {
    const [selectedChat, setSelectedChat] = useState(chats[0]);
    
    
    return <div className="flex gap-4 items-stretch h-full">
        <Card
            className="flex-1"
        >
            <div className="flex flex-col gap-4 overflow-scroll">
                <h2>Chats</h2>
                <div className="flex flex-col flex-1 gap-4">
                    {chats.map(c => {
                        const createdAtFormatted = new Date(c.createdAt);
                        
                        return <Card
                            className="flex-1 max-h-32 overflow-hidden"
                            key={c.id}
                            onClick={() => setSelectedChat(c)}
                            style={{backgroundColor: selectedChat.id === c.id ? '#E7F4FF' : undefined}}
                        >
                            <div>
                                <div className="flex flex-row justify-between items-center">
                                    <ChatStatusTag status={c.status as ChatStatus}/>
                                    <p className="text-xs text-grey-700 font-medium">{c.sentiment}</p>
                                    <p className="text-xs text-grey-700 font-medium">{createdAtFormatted.toLocaleString()}</p>
                                </div>
                                <div className="overflow-auto italic max-h-16 h-full">
                                    {c.summary}
                                </div>
                            </div>
                        </Card>
                    })}
                    {chats.length === 0 && <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />}
                
                </div>
            </div>
        </Card>
        {selectedChat ? <ChatMessagesCard
            selectedChat={selectedChat}
            className="w-[400px]"
        /> : <Card className="w-[400px]"><Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        /> </Card>}
    </div>
    
}

export default AssistantResultChats;

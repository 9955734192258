import {Button, Card} from 'antd';
import MultilineText from '../../components/MultilineText/MultilineText.tsx';
import type {Assistant} from "../../gql/graphql.ts";
import type {AssistantInfo} from "../assistants/assistant/assistantTypes.ts";
import Avatar from "../../components/Avatar/Avatar.tsx";
import QuerriIcon from "../../components/QuerriIcon/QuerriIcon.tsx";

interface ChatWelcomeProps {
  assistant: Pick<Assistant, 'identifier' | 'info'>;
  loading: boolean;
  disabled: boolean;
  onStart: () => void;
}


function ChatWelcome({ assistant, loading, disabled, onStart }: ChatWelcomeProps) {
  return (
    <>
      <Card
        className="overflow-clip md:min-w-[400px] max-w-[700px] mx-4 [&_.ant-card-body]:text-center [&_.ant-card-body]:p-20"
        cover={
          <div className="bg-primary p-12 text-white" style={{backgroundColor: assistant.info.colorPrimary}}>
            <div className="flex items-center justify-center gap-4">
              <Avatar
                avatar={(assistant.info as AssistantInfo).avatar}
              />
              <div>
                <h2 className="text-2xl font-bold mt-0 mb-2">
                  {assistant.info?.name ?? 'No name'}
                </h2>
                <h3 className="text-lg font-medium my-0">
                  {assistant.info?.role ?? 'No role'}
                </h3>
              </div>
            </div>
          </div>
        }
      >
        <p className="text-base mt-0 mb-12">
          <MultilineText>{assistant.info?.welcomeMessage ?? 'Hey there! Ready to get started?'}</MultilineText>
        </p>
        <Button
          className=""
          disabled={disabled}
          loading={loading}
          onClick={onStart}
          size="large"
          type="primary"
        >
          {(assistant.info as AssistantInfo).welcomeButtonText ?? 'Start'}
        </Button>
      </Card>
      <a
        className="flex items-center gap-2 my-4 text-sm text-gray-400"
        href={window.location.origin}
      >
        Powered by
        <QuerriIcon color="#DADADA" />
      </a>
    </>
  );
}

export default ChatWelcome;

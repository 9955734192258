import {Tag} from 'antd';
import {ChatStatus} from "../../gql/graphql.ts";

interface ChatStatusTagProps {
  status: ChatStatus;
}

const STATUS_COLORS: Record<ChatStatus, string> = {
  [ChatStatus.Ongoing]: 'blue',
  [ChatStatus.Completed]: 'green',
  [ChatStatus.Abandoned]: 'red',
};

const ChatStatusTag = ({ status }: ChatStatusTagProps) => {
  return (
    <Tag
      color={STATUS_COLORS[status]}
    >
      {status}
    </Tag>
  );
};

export default ChatStatusTag;

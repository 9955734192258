import { Breadcrumb, type BreadcrumbProps } from 'antd';
import { Link } from 'react-router-dom';

import './Header.css';

interface HeaderProps {
  breadcrumb: BreadcrumbProps['items'];
  info?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
}

const Header = ({ breadcrumb, info, content, actions }: HeaderProps) => {
  return (
    <header className="app-header">
      <div className="header-title">
        <Breadcrumb
          items={breadcrumb}
          itemRender={(route, _, routes, paths) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? <span>{route.title}</span> : <Link to={paths.join('/')}>{route.title}</Link>;
          }}
        />
        {info}
      </div>
      {content && (
        <div className="header-content">
          {content}
        </div>
      )}
      <div className="header-actions">
        {actions}
      </div>
    </header>
  )
};

export default Header;

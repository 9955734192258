import type {PropsWithChildren, ReactNode} from "react";
import React from "react";
import {Spin} from "antd";

import './CardGrid.css';

interface CardGridProps {
  loading?: boolean;
  empty?: ReactNode;
}

export default function CardGrid({ loading, empty, children }: PropsWithChildren<CardGridProps>) {
  if (loading) return <Spin size="large" />;
  return React.Children.count(children) === 0 ? empty : (
    <div
      className="card-grid flex-1 relative"
    >
      {children}
    </div>
  );
};

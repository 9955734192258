import ReactGA from "react-ga4";
import {config, MODE} from "./config.ts";

export enum GA_EVENT {
  // built-in events
  LOGIN = "login",
  SIGN_UP = "sign_up",

  // custom events
  GET_STARTED = "GetStarted",
  CLICK_DEMO_CHAT = "ClickDemoChat",
  SUBSCRIBE_NEWSLETTER = "SubscribeNewsletter",
  CREATE_ASSISTANT = "CreateAssistant",
  ACTIVATE_ASSISTANT = "ActivateAssistant",
  SEND_PREVIEW_CHAT_MESSAGE = "SendPreviewChatMessage",
  COPY_LINK = "CopyLink",
  ANALYSE_RESULTS = "AnalyseResults",
}

export function configureGoogleAnalytics(): void {
  ReactGA.initialize(config.tracking.googleAnalyticsId, {
    testMode: config.mode === MODE.DEVELOPMENT,
  });
}

export const GoogleAnalytics = ReactGA;

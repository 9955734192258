import {
  Navigate,
  RouteObject,
  useParams,
} from 'react-router-dom';
import Home from '../website/Home';
import {ReactNode} from "react";

const RedirectToChat = (): ReactNode => {
  const { assistantIdentifier } = useParams();
  return <Navigate replace to={`/app/chat/${assistantIdentifier}`} />;
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'chat/:assistantIdentifier',
    element: <RedirectToChat />,
  },
  {
    path: '*',
    element: <Navigate replace to='/' />,
  },
];

export default routes;

import {Button, Result as AntResult} from "antd";
import type {Assistant} from "../../gql/graphql.ts";
import type {AssistantInfo} from "../assistants/assistant/assistantTypes.ts";

import './Result.css';

interface ResultProps {
  assistant: Pick<Assistant, 'info'>;
}

function Result({ assistant }: ResultProps) {
  const assistantInfo = assistant.info as AssistantInfo;
  const { endMessage, endButtonText, endButtonUrl } = assistantInfo;

  const extra = [];
  if (endButtonText && endButtonUrl) {
    extra.push(
      <Button
        href={endButtonUrl}
        type="primary"
      >
        {endButtonText}
      </Button>
    );
  }

  return (
    <AntResult
      className="result"
      extra={extra}
      status="success"
      subTitle={endMessage || "Thank you for your time"}
      title="Successfully submitted"
    />
  )
}

export default Result;

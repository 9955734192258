import {CalendarDaysIcon, HandRaisedIcon} from '@heroicons/react/24/outline'
import {Button, Input, Form} from "antd";
import {useState} from "react";
import "./NewsLetterSection.css";
import {useCreateSubscriber} from "../../api/subscriber.api.ts";
import {GA_EVENT, GoogleAnalytics} from "../../config/googleAnalytics.ts";

interface NewUserInfo {
    email: string;
}

export default function NewsLetterSection() {
    const [createSubscriber, createSubscriberResult] = useCreateSubscriber();


    const [form] = Form.useForm<NewUserInfo>();

    const [emailError, setEmailError] = useState(false);

    return (
        <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                    <div className="max-w-xl lg:max-w-lg">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Let's talk!</h2>
                        <div className="mt-6 flex max-w-md gap-x-4">
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <Form
                                form={form}
                                layout='vertical'
                                initialValues={{
                                    email: ""
                                }}
                                onValuesChange={() => {
                                    form.validateFields(["email"]).catch(err => {
                                        setEmailError(err.errorFields.length > 0);
                                    });
                                }}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: "Please provide a valid e-mail",
                                        },
                                    ]}
                                    className="w-96 m-0"
                                >
                                    <Input
                                        id="email-address"
                                        name="email"
                                        placeholder="Enter your email"
                                        className="rounded-md border-0"
                                        size="large"
                                    />
                                </Form.Item>
                            </Form>
                            <Button
                                size="large"
                                type="primary"
                                disabled={emailError}
                                loading={createSubscriberResult.loading}
                                onClick={async () => {
                                    const email = form.getFieldValue("email");
                                    if (!email) {
                                        return;
                                    }
                                    await createSubscriber({
                                        variables: {
                                            info: {email}
                                        }
                                    })
                                    GoogleAnalytics.event(GA_EVENT.SUBSCRIBE_NEWSLETTER, { email });
                                }}
                            >
                                Share
                            </Button>
                        </div>
                        <h2 className="mt-10 text-3xl font-bold tracking-tight text-white sm:text-4xl">Or... let's chat?
                            ;)</h2>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => {
                                GoogleAnalytics.event(GA_EVENT.CLICK_DEMO_CHAT);
                                open("https://querii.org/app/chat/ed9731e2-98a8-4eef-b0bc-c8ebabd3a403")
                            }}
                        >
                            Chat
                        </Button>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                        <div className="flex flex-col items-start">
                            <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                                <CalendarDaysIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            <dt className="mt-4 font-semibold text-white">We'll get in touch</dt>
                            <dd className="ms-0 mt-2 leading-7 text-gray-400">
                                Let's discover how to improve your customer interactions together
                            </dd>
                        </div>
                        <div className="flex flex-col items-start">
                            <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                                <HandRaisedIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                            </div>
                            <dt className="mt-4 font-semibold text-white">No spam</dt>
                            <dd className="ms-0 mt-2 leading-7 text-gray-400">
                                We promise you
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#1677FF] to-[#00d4ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
        </div>
    )
}

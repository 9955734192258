import {
  useMutation
} from "@apollo/client";
import type {
  DocumentNode,
  MutationFunctionOptions,
  MutationQueryReducer,
  TypedDocumentNode,
} from "@apollo/client";
import type {ApolloCache, DefaultContext, OperationVariables} from "@apollo/client/core";
import type {MutationHookOptions, MutationTuple, NoInfer} from "@apollo/client/react/types/types";
import type {IgnoreModifier} from "@apollo/client/cache/core/types/common";
import type {FetchResult} from "@apollo/client/link/core";
import type {InternalRefetchQueriesInclude} from "@apollo/client/core/types";

export interface AlteredMutationHookOptions<
  TGQLData = any,
  TGQLVariables = OperationVariables,
  TAlteredData = TGQLData,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
> extends MutationHookOptions<TGQLData, TGQLVariables, TContext, TCache> {
  mapGQLDataToAlteredData?: (data: TGQLData) => TAlteredData;
  mapAlteredDataToGQLData?: (data: TAlteredData) => TGQLData;
}

function isFunctionOptimisticResponse<TGQLData, TGQLVariables>(
  optimisticResponse: TGQLData | ((variables: TGQLVariables, { IGNORE }: {
    IGNORE: IgnoreModifier;
  }) => TGQLData),
): optimisticResponse is (variables: TGQLVariables, { IGNORE }: {
  IGNORE: IgnoreModifier;
}) => TGQLData {
  return typeof optimisticResponse === "function";
}

function isFunctionRefetchQueries<TGQLData>(
  refetchQueries: ((result: FetchResult<TGQLData>) => InternalRefetchQueriesInclude) | InternalRefetchQueriesInclude,
): refetchQueries is (result: FetchResult<TGQLData>) => InternalRefetchQueriesInclude {
  return typeof refetchQueries === "function";
}

function nonNullableMap<In, Out>(fn: (data: In) => Out, data: In | null | undefined): Out | null | undefined {
  if (data === null) return null;
  if (data === undefined) return undefined;
  return fn(data);
}

export function useAlteredMutation<
  TGQLData = any,
  TGQLVariables = OperationVariables,
  TAlteredData = TGQLData,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TGQLData, TGQLVariables>,
  {
    mapGQLDataToAlteredData = (data) => data as TAlteredData,
    mapAlteredDataToGQLData = (data) => data as TGQLData,
    ...options
  }: AlteredMutationHookOptions<NoInfer<TGQLData>, NoInfer<TGQLVariables>, NoInfer<TAlteredData>, TContext, TCache>,
): MutationTuple<
  TAlteredData,
  TGQLVariables,
  TContext,
  TCache
> {
  const [mutate, result] = useMutation(mutation, options);
  const alteredMutate = async (alteredMutateOptions?: MutationFunctionOptions<NoInfer<TAlteredData>, NoInfer<TGQLVariables>, TContext, TCache>) => {
    let mutateOptions: MutationFunctionOptions<NoInfer<TGQLData>, NoInfer<TGQLVariables>, TContext, TCache> | undefined;
    if (alteredMutateOptions) {
      const {
        onCompleted,
        optimisticResponse,
        updateQueries,
        refetchQueries,
        update,
        ...rest
      } = alteredMutateOptions;
      mutateOptions = {
        ...rest,
      };

      if (onCompleted) {
        mutateOptions.onCompleted = (data) => {
          onCompleted(mapGQLDataToAlteredData(data));
        }
      }
      if (optimisticResponse) {
        if (isFunctionOptimisticResponse(optimisticResponse)) {
          mutateOptions.optimisticResponse = (variables, opts) => mapAlteredDataToGQLData(optimisticResponse(variables, opts));
        } else {
          mutateOptions.optimisticResponse = mapAlteredDataToGQLData(optimisticResponse);
        }
      }
      if (updateQueries) {
        mutateOptions.updateQueries = Object.fromEntries(
          Object.entries(updateQueries)
            .map(([query, updateFunction]) => [
              query,
              (
                previousResult: Parameters<MutationQueryReducer<TGQLData>>[0],
                { mutationResult, ...otherOptions }: Parameters<MutationQueryReducer<TGQLData>>[1],
              ) => updateFunction(
                previousResult,
                {
                  mutationResult: {
                    ...mutationResult,
                    data: nonNullableMap(mapGQLDataToAlteredData, mutationResult.data),
                  },
                  ...otherOptions,
                },
              ),
            ]),
        );
      }
      if (refetchQueries) {
        if (isFunctionRefetchQueries(refetchQueries)) {
          mutateOptions.refetchQueries = (queryResult) => refetchQueries({
            ...queryResult,
            data: nonNullableMap(mapGQLDataToAlteredData, queryResult.data),
          });
        } else {
          mutateOptions.refetchQueries = refetchQueries;
        }
      }
      if (update) {
        mutateOptions.update = (
          cache,
          { data, ...restOfResult },
          updateOptions,
        ) => {
          update(
            cache,
            {
              ...restOfResult,
              data: nonNullableMap(mapGQLDataToAlteredData, data),
            },
            updateOptions,
          );
        };
      }
    }
    const { data, ...rest } = await mutate(mutateOptions);
    return { data: nonNullableMap(mapGQLDataToAlteredData, data), ...rest };
  }
  return [alteredMutate, { ...result, data: nonNullableMap(mapGQLDataToAlteredData, result.data) }];
}

import { Assistant } from "../../../../gql/graphql";
import {Alert, Button, Card} from "antd";
import {UndoOutlined} from "@ant-design/icons";
import ChatLoaded from "../../../chat/ChatLoaded.tsx";
import {useCompletePreviewChatMutation, useStartPreviewChat} from "../../../../api/chat.api.ts";
import {useState} from "react";
import {v4} from "uuid";

export interface ChatPreviewProps {
  assistant: Pick<Assistant, 'identifier' | 'info'>;
  disabled: boolean;
}

export function ChatPreview({ assistant, disabled }: ChatPreviewProps) {
  const [chatSessionUUID, setChatSessionUUID] = useState(v4());

  const assistantReadyForPreview = assistant.info?.goal
    && assistant.info?.name
    && assistant.info?.role;

  return (
    <Card
      className="flex-[2] flex flex-col [&>.ant-card-body]:flex-1 [&>.ant-card-body]:flex [&>.ant-card-body]:flex-col [&>.ant-card-body]:overflow-clip"
      title="Preview"
      extra={(
        <Button
          icon={<UndoOutlined />}
          onClick={() => setChatSessionUUID(v4())}
        >
          Restart
        </Button>
      )}
    >
      {!assistantReadyForPreview && (<Alert
        message="Give your assistant a goal, name and role to try the chat."
      />)}
      <ChatLoaded
        key={chatSessionUUID}
        assistant={assistant}
        useStartChat={useStartPreviewChat}
        useCompleteChat={useCompletePreviewChatMutation}
        disabled={disabled || !assistantReadyForPreview}
      />
    </Card>
  );
}

import { Amplify } from 'aws-amplify';
import { config } from './config.ts';

export const configureAmplify = () =>
    Amplify.configure({
        Auth: {
            region: config.auth.region,
            userPoolId: config.auth.userPoolId,
            userPoolWebClientId: config.auth.userPoolAppClientId,
        },
    });

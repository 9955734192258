import {Button} from "antd";
import {GA_EVENT, GoogleAnalytics} from "../../config/googleAnalytics.ts";

export interface GetStartedButtonProps {
  className?: string;
  children: React.ReactNode;
}

export default function GetStartedButton({ className, children }: GetStartedButtonProps) {
  return (
    <Button
      className={className}
      size="large"
      type="primary"
      onClick={() => {
        GoogleAnalytics.event(GA_EVENT.GET_STARTED);
        window.location.href = "/app"
      }}
    >
      {children}
    </Button>
  );
}
import type {HTMLProps, ReactNode} from "react";
import classNames from "classnames";

type LabelProps = HTMLProps<HTMLDivElement>;

export default function Label({ children, className, ...otherProps }: LabelProps): ReactNode {
  return (
    <div
      className={classNames("ml-[11px] mb-2 text-gray-400 uppercase text-[0.7rem] font-semibold", className)}
      {...otherProps}
    >
      {children}
    </div>
  );
}

import {theme} from "antd";
import type {ReactElement} from "react";
import { useEffect, useState} from "react";
import type {Assistant, Chat} from "../../gql/graphql.ts";
import type {CompleteChatMutationTuple, StartChatMutationTuple} from "../../api/chat.api.ts";
import type {AssistantInfo} from "../assistants/assistant/assistantTypes.ts";
import Result from "./Result.tsx";
import ChatUI from "./ChatUI.tsx";
import ChatWelcome from "./ChatWelcome.tsx";
import "./ChatLoaded.css";

enum STAGE {
    WELCOME = 'WELCOME',
    CHAT = 'CHAT',
    RESULT = 'RESULT',
}

const {useToken} = theme;

interface ChatLoadedProps {
    assistant: Pick<Assistant, 'identifier' | 'info'>;
    useStartChat: () => StartChatMutationTuple;
    useCompleteChat: () => CompleteChatMutationTuple;
    disabled?: boolean;
}

function ChatLoaded({ assistant, useStartChat, useCompleteChat, disabled = false }: ChatLoadedProps): ReactElement {
    const assistantInfo = assistant.info as AssistantInfo;
    
    const [startChat, startChatMutation] = useStartChat();

    const [stage, setStage] = useState<STAGE>(STAGE.WELCOME);
    const [chat, setChat] = useState<Pick<Chat, "id" | "messages"> | null>(null);

    const {token} = useToken();

    useEffect(() => {
        document.body.style.backgroundColor = token.colorBgContainer;
    }, [token])

    return <>
        {assistantInfo.companyIcon ? <img alt="companyLogo" className="chat-loaded-img" src={atob(assistantInfo.companyIcon ?? "")}/> : null}
        <div className="chat-loaded">
            {stage === STAGE.WELCOME && (
              <ChatWelcome
                assistant={assistant}
                disabled={disabled}
                loading={startChatMutation.loading}
                onStart={() => {
                    startChat({
                        variables: {
                            assistantIdentifier: assistant.identifier,
                        },
                        onCompleted: (data) => {
                            setChat(data?.chat ?? null)
                            setStage(STAGE.CHAT);
                        }
                    })
                }}
              />
            )}
            {stage === STAGE.CHAT && chat ? <div className="max-w-[1200px] flex-1 flex flex-col px-4 pb-4 overflow-clip">
                  <ChatUI
                    assistant={assistant}
                    chat={chat}
                    onSubmit={() => { setStage(STAGE.RESULT); }}
                    useCompleteChat={useCompleteChat}
                  />
              </div> : null}
            {stage === STAGE.RESULT && (
              <Result assistant={assistant}/>
            )}
        </div>
    </>
}

export default ChatLoaded;

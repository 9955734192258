import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {Button, Dropdown, Modal, Result, Tabs, type TabsProps, Tooltip} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
import Page from '../../../layout/Page/Page.tsx';
import Header from '../../../layout/Header/Header.tsx';
import {idValidator, useParam} from '../../../util/useParam.ts';
import ConfigureAssistant from './configure/ConfigureAssistant.tsx';
import ShareAssistant from './ShareAssistant.tsx';
import AssistantStatusTag from '../../../components/assistant/AssistantStatusTag.tsx';
import {useLocationHash} from '../../../util/useLocationHash.ts';
import AssistantResults from './AssistantResults/AssistantResults.tsx';
import {gql, useQuery} from "@apollo/client";
import {AssistantQuery, AssistantQueryVariables, AssistantStatus} from "../../../gql/graphql.ts";
import {useDeleteAssistantMutation, useUpdateAssistantMutation} from "../../../api/assistant.api.ts";

import './Assistant.css';
import {GA_EVENT, GoogleAnalytics} from "../../../config/googleAnalytics.ts";

const useAssistant = (id: number) => {
    const { data, ...query } = useQuery<AssistantQuery, AssistantQueryVariables>(gql`
        query Assistant($id: Int!) {
            assistant(id: $id) {
                id
                identifier
                status
                info
            }
        }
    `, {
      variables: {
        id,
      },
    });
  return {
    assistant: data?.assistant,
    ...query,
  };
};

const AssistantDetail = () => {
  const navigate = useNavigate();
  const [hash, setHash] = useLocationHash();

  const assistantId = useParam('assistantId', idValidator) ?? 0;
  const { assistant, ...assistantQuery } = useAssistant(assistantId);

  useEffect(() => {
    if (hash || !assistant) return;
    if (assistant.status === AssistantStatus.Draft) setHash('configure');
    else setHash('analyse');
  }, [hash, assistant, setHash]);

  const [updateAssistant] = useUpdateAssistantMutation();
  const [removeAssistant, removeAssistantMutation] = useDeleteAssistantMutation();

  const readyToPublish = assistant
    && assistant.status === AssistantStatus.Draft
    && assistant.info?.goal
    && assistant.info?.name
    && assistant.info?.role;

  if (assistantQuery.error) return (
    <Result
      status="404"
      title="Assistant not found"
      subTitle="The assistant you're looking for doesn't exists, or you don't have access to it. Try again or contact support if the problem persists."
      extra={[
        <Button type="primary" onClick={() => navigate('..')}>
          Back to assistants list
        </Button>,
        <Button onClick={() => window.location.reload()}>Reload</Button>,
      ]}
    />
  );

  const tabs: TabsProps['items'] = [
    {
      key: 'configure',
      label: 'Configure',
    },
    {
      key: 'share',
      label: 'Share',
      disabled: assistant?.status !== AssistantStatus.Active,
    },
    {
      key: 'analyse',
      label: 'Analyse',
    },
  ];

  return (
    <Page
      className="assistant-detail"
      header={<Header
        breadcrumb={[
          {
            title: 'Assistants',
            path: '..',
          },
          {
            title: assistant?.info?.name ?? <span style={{color: 'grey'}}>No name</span>,
          }
        ]}
        info={assistant ? <AssistantStatusTag status={assistant.status} /> : undefined}
        content={<Tabs
          items={tabs}
          activeKey={hash}
          onChange={(key) => setHash(key)}
        />}
        actions={
          <>
            {assistant?.status === AssistantStatus.Draft && (
              <Tooltip title={readyToPublish ? 'Activate & share to start collecting results' : 'Please fill in all required fields'}>
                <Button
                  type="primary"
                  disabled={!readyToPublish}
                  onClick={() => Modal.confirm({
                    title: 'Are you sure?',
                    content: 'Are you sure you want to activate this assistant? Once activated, you won\'t be able to alter it anymore.',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => updateAssistant({
                      variables: {
                        id: assistantId,
                        patch: {
                          status: AssistantStatus.Active,
                        },
                      },
                      onCompleted: () => {
                        GoogleAnalytics.event(GA_EVENT.ACTIVATE_ASSISTANT);
                        setHash('share');
                      },
                    }),
                  })}
                  loading={assistantQuery.loading}
                >
                  Activate
                </Button>
              </Tooltip>
            )}
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'delete',
                    label: 'Delete assistant',
                    danger: true,
                  }
                ],
                onClick: ({ key }) => {
                  if (key === 'delete') {
                    Modal.confirm({
                      title: 'Are you sure?',
                      okText: 'Yes',
                      cancelText: 'No',
                      okButtonProps: {
                        loading: removeAssistantMutation.loading,
                      },
                      content: 'Are you sure you want to delete this assistant?',
                      onOk: () => removeAssistant({
                        variables: {
                          id: assistantId,
                        },
                        onCompleted: () => { navigate('..') },
                      }),
                    })
                  }
                }}
              }
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </>
        }
      />}
    >
      <Page.Wrapper className="flex-1">
        {assistantQuery.loading && 'Loading...'}
        {hash === 'configure' && assistant && (
          <ConfigureAssistant assistant={assistant} />
        )}
        {hash === 'share' && assistant && (
          <ShareAssistant assistant={assistant} />
        )}
        {hash === 'analyse' && assistant && (
          <AssistantResults assistant={assistant} />
        )}
      </Page.Wrapper>
    </Page>
  );
};

export default AssistantDetail;

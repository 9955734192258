export enum MODE {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
}

export const config = {
    mode: import.meta.env.MODE as MODE,
    api: {
        baseURL: import.meta.env.VITE_API_BASE_URL as string,
    },
    auth: {
        region: import.meta.env.VITE_USER_POOL_REGION as string,
        userPoolId: import.meta.env.VITE_USER_POOL_ID as string,
        userPoolAppClientId: import.meta.env.VITE_USER_POOL_APP_CLIENT_ID as string,
    },
    tracking: {
        googleAnalyticsId: import.meta.env.VITE_GOOGLE_ANALYTICS_ID as string,
        hotjarSiteId: Number.parseInt(import.meta.env.VITE_HOTJAR_SITE_ID as string, 10),
    },
}

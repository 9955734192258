import classNames from "classnames";
import useScrollPosition from "../../util/useScrollPosition.ts";
import QuerriIcon from "../../components/QuerriIcon/QuerriIcon.tsx";
import GetStartedButton from "./GetStartedButton.tsx";

export default function Header() {
  const scrollPosition = useScrollPosition();

  return (
    <header className={classNames("fixed inset-x-0 top-0 z-50 transition-all", scrollPosition > 0 && "bg-white shadow")}>
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1 items-center">
          <QuerriIcon />
        </div>
        <div className="flex flex-1 justify-end items-center">
          <a href="/app" className="mr-4 text-md font-semibold leading-6 text-gray-900">
            Log in
          </a>
          <GetStartedButton>
            Sign up
          </GetStartedButton>
        </div>
      </nav>
    </header>
  );
};

import {ColorPicker, Select} from "antd";
import {Assistant} from "../../../../gql/graphql.ts";
import {AssistantInfo} from "../assistantTypes.ts";
import Label from "../../../../components/typography/Label.tsx";

interface ConfigureStyleProps {
  assistant: Pick<Assistant, 'info'>;
  updateAssistantInfo: (info: Partial<AssistantInfo>) => void;
  editable: boolean;
}

export default function ConfigureStyle({ assistant, updateAssistantInfo, editable }: ConfigureStyleProps) {
  return (
    <div>
      <Label>Banner</Label>
      <div className="flex items-center gap-2">
        <Select
          className="w-[100px]"
          options={[
            { label: 'Color', value: 'color' },
            { label: 'Image', value: 'image', disabled: true },
          ]}
          value="color"
          disabled={!editable}
        />
        <ColorPicker
          disabledAlpha
          presets={[
            {
              label: 'Sample',
              colors: ['#f5222d', '#fa541c', '#fa8c16', '#faad14', '#fadb14', '#a0d911', '#52c41a', '#13c2c2', '#1677ff', '#2f54eb', '#722ed1', '#eb2f96'],
            },
          ]}
          value={(assistant.info as AssistantInfo | undefined)?.colorPrimary}
          onChange={(color) => updateAssistantInfo({ colorPrimary: color.toHexString() })}
          disabled={!editable}
        />
      </div>
    </div>
  );
};

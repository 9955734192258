/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any; }
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any; }
};

/** All input for the `archiveAssistant` mutation. */
export type ArchiveAssistantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our `archiveAssistant` mutation. */
export type ArchiveAssistantPayload = {
  __typename?: 'ArchiveAssistantPayload';
  assistant?: Maybe<Assistant>;
  /** An edge for our `Assistant`. May be used by Relay 1. */
  assistantEdge?: Maybe<AssistantsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `archiveAssistant` mutation. */
export type ArchiveAssistantPayloadAssistantEdgeArgs = {
  orderBy?: InputMaybe<Array<AssistantsOrderBy>>;
};

export type Assistant = Node & {
  __typename?: 'Assistant';
  archivedAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Chat`. */
  chats: ChatsConnection;
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  identifier: Scalars['UUID']['output'];
  info: Scalars['JSON']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: AssistantStatus;
  updatedAt: Scalars['Datetime']['output'];
};


export type AssistantChatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ChatCondition>;
  filter?: InputMaybe<ChatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ChatsOrderBy>>;
};

/**
 * A condition to be used against `Assistant` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AssistantCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `identifier` field. */
  identifier?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type AssistantConfigurationPayload = {
  __typename?: 'AssistantConfigurationPayload';
  info: Scalars['JSON']['output'];
  query?: Maybe<Query>;
};

/** A filter to be used against `Assistant` object types. All fields are combined with a logical ‘and.’ */
export type AssistantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AssistantFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `identifier` field. */
  identifier?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AssistantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AssistantFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `Assistant` */
export type AssistantInput = {
  archivedAt?: InputMaybe<Scalars['Datetime']['input']>;
  identifier?: InputMaybe<Scalars['UUID']['input']>;
  info?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<AssistantStatus>;
};

/** Represents an update to a `Assistant`. Fields that are set will be updated. */
export type AssistantPatch = {
  archivedAt?: InputMaybe<Scalars['Datetime']['input']>;
  identifier?: InputMaybe<Scalars['UUID']['input']>;
  info?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<AssistantStatus>;
};

export enum AssistantStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT'
}

/** A connection to a list of `Assistant` values. */
export type AssistantsConnection = {
  __typename?: 'AssistantsConnection';
  /** A list of edges which contains the `Assistant` and cursor to aid in pagination. */
  edges: Array<AssistantsEdge>;
  /** A list of `Assistant` objects. */
  nodes: Array<Assistant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Assistant` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Assistant` edge in the connection. */
export type AssistantsEdge = {
  __typename?: 'AssistantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Assistant` at the end of the edge. */
  node: Assistant;
};

/** Methods to use when ordering `Assistant`. */
export enum AssistantsOrderBy {
  IdentifierAsc = 'IDENTIFIER_ASC',
  IdentifierDesc = 'IDENTIFIER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type BasicAssistantInfo = {
  __typename?: 'BasicAssistantInfo';
  identifier?: Maybe<Scalars['UUID']['output']>;
  info?: Maybe<Scalars['JSON']['output']>;
};

export type BasicChatInfo = {
  __typename?: 'BasicChatInfo';
  activeStepIndex?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  messages?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<ChatStatus>;
};

export type BasicChatPayload = {
  __typename?: 'BasicChatPayload';
  chat?: Maybe<BasicChatInfo>;
  query?: Maybe<Query>;
};

export type BasicMessages = {
  __typename?: 'BasicMessages';
  messages: Scalars['JSON']['output'];
};

export type Chat = Node & {
  __typename?: 'Chat';
  activeStepIndex?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Assistant` that is related to this `Chat`. */
  assistant?: Maybe<Assistant>;
  assistantId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  info: Scalars['JSON']['output'];
  keyTopics?: Maybe<Scalars['String']['output']>;
  messages: Scalars['JSON']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  results?: Maybe<Scalars['JSON']['output']>;
  sentiment?: Maybe<Scalars['String']['output']>;
  status: ChatStatus;
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ChatType>;
  updatedAt: Scalars['Datetime']['output'];
};

/** A condition to be used against `Chat` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ChatCondition = {
  /** Checks for equality with the object’s `assistantId` field. */
  assistantId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `Chat` object types. All fields are combined with a logical ‘and.’ */
export type ChatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ChatFilter>>;
  /** Filter by the object’s `assistantId` field. */
  assistantId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ChatFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ChatFilter>>;
};

/** An input for mutations affecting `Chat` */
export type ChatInput = {
  activeStepIndex?: InputMaybe<Scalars['Int']['input']>;
  info?: InputMaybe<Scalars['JSON']['input']>;
  keyTopics?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<Scalars['JSON']['input']>;
  results?: InputMaybe<Scalars['JSON']['input']>;
  sentiment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ChatStatus>;
  summary?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ChatType>;
};

/** Represents an update to a `Chat`. Fields that are set will be updated. */
export type ChatPatch = {
  activeStepIndex?: InputMaybe<Scalars['Int']['input']>;
  info?: InputMaybe<Scalars['JSON']['input']>;
  keyTopics?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<Scalars['JSON']['input']>;
  results?: InputMaybe<Scalars['JSON']['input']>;
  sentiment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ChatStatus>;
  summary?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ChatType>;
};

export enum ChatStatus {
  Abandoned = 'ABANDONED',
  Completed = 'COMPLETED',
  Ongoing = 'ONGOING'
}

export enum ChatType {
  Preview = 'PREVIEW',
  Standard = 'STANDARD'
}

/** A connection to a list of `Chat` values. */
export type ChatsConnection = {
  __typename?: 'ChatsConnection';
  /** A list of edges which contains the `Chat` and cursor to aid in pagination. */
  edges: Array<ChatsEdge>;
  /** A list of `Chat` objects. */
  nodes: Array<Chat>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Chat` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Chat` edge in the connection. */
export type ChatsEdge = {
  __typename?: 'ChatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Chat` at the end of the edge. */
  node: Chat;
};

/** Methods to use when ordering `Chat`. */
export enum ChatsOrderBy {
  AssistantIdAsc = 'ASSISTANT_ID_ASC',
  AssistantIdDesc = 'ASSISTANT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CompleteChatInput = {
  assistantIdentifier: Scalars['UUID']['input'];
  id: Scalars['Int']['input'];
  userMessage: Scalars['String']['input'];
};

export type CompleteMessagesInput = {
  messages: Scalars['String']['input'];
};

/** All input for the create `Assistant` mutation. */
export type CreateAssistantInput = {
  /** The `Assistant` to be created by this mutation. */
  assistant: AssistantInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `Assistant` mutation. */
export type CreateAssistantPayload = {
  __typename?: 'CreateAssistantPayload';
  /** The `Assistant` that was created by this mutation. */
  assistant?: Maybe<Assistant>;
  /** An edge for our `Assistant`. May be used by Relay 1. */
  assistantEdge?: Maybe<AssistantsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Assistant` mutation. */
export type CreateAssistantPayloadAssistantEdgeArgs = {
  orderBy?: InputMaybe<Array<AssistantsOrderBy>>;
};

/** All input for the create `Chat` mutation. */
export type CreateChatInput = {
  /** The `Chat` to be created by this mutation. */
  chat: ChatInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `Chat` mutation. */
export type CreateChatPayload = {
  __typename?: 'CreateChatPayload';
  /** Reads a single `Assistant` that is related to this `Chat`. */
  assistant?: Maybe<Assistant>;
  /** The `Chat` that was created by this mutation. */
  chat?: Maybe<Chat>;
  /** An edge for our `Chat`. May be used by Relay 1. */
  chatEdge?: Maybe<ChatsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Chat` mutation. */
export type CreateChatPayloadChatEdgeArgs = {
  orderBy?: InputMaybe<Array<ChatsOrderBy>>;
};

/** All input for the `createSubscriber` mutation. */
export type CreateSubscriberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['JSON']['input']>;
};

/** The output of our `createSubscriber` mutation. */
export type CreateSubscriberPayload = {
  __typename?: 'CreateSubscriberPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAssistantByNodeId` mutation. */
export type DeleteAssistantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Assistant` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteAssistant` mutation. */
export type DeleteAssistantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `Assistant` mutation. */
export type DeleteAssistantPayload = {
  __typename?: 'DeleteAssistantPayload';
  /** The `Assistant` that was deleted by this mutation. */
  assistant?: Maybe<Assistant>;
  /** An edge for our `Assistant`. May be used by Relay 1. */
  assistantEdge?: Maybe<AssistantsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedAssistantNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Assistant` mutation. */
export type DeleteAssistantPayloadAssistantEdgeArgs = {
  orderBy?: InputMaybe<Array<AssistantsOrderBy>>;
};

/** All input for the `deleteChatByNodeId` mutation. */
export type DeleteChatByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Chat` to be deleted. */
  nodeId: Scalars['ID']['input'];
};

/** All input for the `deleteChat` mutation. */
export type DeleteChatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** The output of our delete `Chat` mutation. */
export type DeleteChatPayload = {
  __typename?: 'DeleteChatPayload';
  /** Reads a single `Assistant` that is related to this `Chat`. */
  assistant?: Maybe<Assistant>;
  /** The `Chat` that was deleted by this mutation. */
  chat?: Maybe<Chat>;
  /** An edge for our `Chat`. May be used by Relay 1. */
  chatEdge?: Maybe<ChatsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedChatNodeId?: Maybe<Scalars['ID']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Chat` mutation. */
export type DeleteChatPayloadChatEdgeArgs = {
  orderBy?: InputMaybe<Array<ChatsOrderBy>>;
};

export type FinishChatInput = {
  assistantIdentifier: Scalars['UUID']['input'];
  id: Scalars['Int']['input'];
};

/** Indicates whether archived items should be included in the results or not. */
export enum IncludeArchivedOption {
  /** Only include archived items (i.e. exclude non-archived items). */
  Exclusively = 'EXCLUSIVELY',
  /** If there is a parent GraphQL record and it is archived then this is equivalent to YES, in all other cases this is equivalent to NO. */
  Inherit = 'INHERIT',
  /** Exclude archived items. */
  No = 'NO',
  /** Include archived items. */
  Yes = 'YES'
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  archiveAssistant?: Maybe<ArchiveAssistantPayload>;
  completeChat?: Maybe<BasicChatPayload>;
  completeMessages?: Maybe<BasicMessages>;
  completePreviewChat?: Maybe<BasicChatPayload>;
  /** Creates a single `Assistant`. */
  createAssistant?: Maybe<CreateAssistantPayload>;
  /** Creates a single `Chat`. */
  createChat?: Maybe<CreateChatPayload>;
  createSubscriber?: Maybe<CreateSubscriberPayload>;
  /** Deletes a single `Assistant` using a unique key. */
  deleteAssistant?: Maybe<DeleteAssistantPayload>;
  /** Deletes a single `Assistant` using its globally unique id. */
  deleteAssistantByNodeId?: Maybe<DeleteAssistantPayload>;
  /** Deletes a single `Chat` using a unique key. */
  deleteChat?: Maybe<DeleteChatPayload>;
  /** Deletes a single `Chat` using its globally unique id. */
  deleteChatByNodeId?: Maybe<DeleteChatPayload>;
  finishChat?: Maybe<BasicChatPayload>;
  recommendAssistantConfiguration?: Maybe<AssistantConfigurationPayload>;
  setChatKeyTopics?: Maybe<SetChatKeyTopicsPayload>;
  setChatSentiment?: Maybe<SetChatSentimentPayload>;
  setChatSummary?: Maybe<SetChatSummaryPayload>;
  startChat?: Maybe<BasicChatPayload>;
  startPreviewChat?: Maybe<BasicChatPayload>;
  /** Updates a single `Assistant` using a unique key and a patch. */
  updateAssistant?: Maybe<UpdateAssistantPayload>;
  /** Updates a single `Assistant` using its globally unique id and a patch. */
  updateAssistantByNodeId?: Maybe<UpdateAssistantPayload>;
  /** Updates a single `Chat` using a unique key and a patch. */
  updateChat?: Maybe<UpdateChatPayload>;
  /** Updates a single `Chat` using its globally unique id and a patch. */
  updateChatByNodeId?: Maybe<UpdateChatPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationArchiveAssistantArgs = {
  input: ArchiveAssistantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompleteChatArgs = {
  input: CompleteChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompleteMessagesArgs = {
  input: CompleteMessagesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompletePreviewChatArgs = {
  input: CompleteChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAssistantArgs = {
  input: CreateAssistantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChatArgs = {
  input: CreateChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSubscriberArgs = {
  input: CreateSubscriberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAssistantArgs = {
  input: DeleteAssistantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAssistantByNodeIdArgs = {
  input: DeleteAssistantByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChatArgs = {
  input: DeleteChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChatByNodeIdArgs = {
  input: DeleteChatByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFinishChatArgs = {
  input: FinishChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRecommendAssistantConfigurationArgs = {
  input: RecommendAssistantConfigurationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetChatKeyTopicsArgs = {
  input: SetChatKeyTopicsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetChatSentimentArgs = {
  input: SetChatSentimentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetChatSummaryArgs = {
  input: SetChatSummaryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartChatArgs = {
  input: StartChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartPreviewChatArgs = {
  input: StartChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAssistantArgs = {
  input: UpdateAssistantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAssistantByNodeIdArgs = {
  input: UpdateAssistantByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChatArgs = {
  input: UpdateChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChatByNodeIdArgs = {
  input: UpdateChatByNodeIdInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  assistant?: Maybe<Assistant>;
  assistantByIdentifier?: Maybe<BasicAssistantInfo>;
  /** Reads a single `Assistant` using its globally unique `ID`. */
  assistantByNodeId?: Maybe<Assistant>;
  /** Reads and enables pagination through a set of `Assistant`. */
  assistants?: Maybe<AssistantsConnection>;
  chat?: Maybe<Chat>;
  /** Reads a single `Chat` using its globally unique `ID`. */
  chatByNodeId?: Maybe<Chat>;
  /** Reads and enables pagination through a set of `Chat`. */
  chats?: Maybe<ChatsConnection>;
  currentUserId?: Maybe<Scalars['UUID']['output']>;
  getChat?: Maybe<BasicChatInfo>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
};


/** The root query type which gives access points into the data universe. */
export type QueryAssistantArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAssistantByIdentifierArgs = {
  identifier?: InputMaybe<Scalars['UUID']['input']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAssistantByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAssistantsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AssistantCondition>;
  filter?: InputMaybe<AssistantFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AssistantsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChatArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChatByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChatsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ChatCondition>;
  filter?: InputMaybe<ChatFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ChatsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetChatArgs = {
  assistantIdentifier?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};

export type RecommendAssistantConfigurationInput = {
  assistantId: Scalars['Int']['input'];
};

/** All input for the `setChatKeyTopics` mutation. */
export type SetChatKeyTopicsInput = {
  assistantIdentifier?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  newKeyTopics?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `setChatKeyTopics` mutation. */
export type SetChatKeyTopicsPayload = {
  __typename?: 'SetChatKeyTopicsPayload';
  basicChatInfo?: Maybe<BasicChatInfo>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setChatSentiment` mutation. */
export type SetChatSentimentInput = {
  assistantIdentifier?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  newSentiment?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `setChatSentiment` mutation. */
export type SetChatSentimentPayload = {
  __typename?: 'SetChatSentimentPayload';
  basicChatInfo?: Maybe<BasicChatInfo>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setChatSummary` mutation. */
export type SetChatSummaryInput = {
  assistantIdentifier?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  newSummary?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `setChatSummary` mutation. */
export type SetChatSummaryPayload = {
  __typename?: 'SetChatSummaryPayload';
  basicChatInfo?: Maybe<BasicChatInfo>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type StartChatInput = {
  assistantIdentifier: Scalars['UUID']['input'];
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** All input for the `updateAssistantByNodeId` mutation. */
export type UpdateAssistantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Assistant` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Assistant` being updated. */
  patch: AssistantPatch;
};

/** All input for the `updateAssistant` mutation. */
export type UpdateAssistantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  /** An object where the defined keys will be set on the `Assistant` being updated. */
  patch: AssistantPatch;
};

/** The output of our update `Assistant` mutation. */
export type UpdateAssistantPayload = {
  __typename?: 'UpdateAssistantPayload';
  /** The `Assistant` that was updated by this mutation. */
  assistant?: Maybe<Assistant>;
  /** An edge for our `Assistant`. May be used by Relay 1. */
  assistantEdge?: Maybe<AssistantsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Assistant` mutation. */
export type UpdateAssistantPayloadAssistantEdgeArgs = {
  orderBy?: InputMaybe<Array<AssistantsOrderBy>>;
};

/** All input for the `updateChatByNodeId` mutation. */
export type UpdateChatByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The globally unique `ID` which will identify a single `Chat` to be updated. */
  nodeId: Scalars['ID']['input'];
  /** An object where the defined keys will be set on the `Chat` being updated. */
  patch: ChatPatch;
};

/** All input for the `updateChat` mutation. */
export type UpdateChatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  /** An object where the defined keys will be set on the `Chat` being updated. */
  patch: ChatPatch;
};

/** The output of our update `Chat` mutation. */
export type UpdateChatPayload = {
  __typename?: 'UpdateChatPayload';
  /** Reads a single `Assistant` that is related to this `Chat`. */
  assistant?: Maybe<Assistant>;
  /** The `Chat` that was updated by this mutation. */
  chat?: Maybe<Chat>;
  /** An edge for our `Chat`. May be used by Relay 1. */
  chatEdge?: Maybe<ChatsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Chat` mutation. */
export type UpdateChatPayloadChatEdgeArgs = {
  orderBy?: InputMaybe<Array<ChatsOrderBy>>;
};

export type ListAssistantIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAssistantIdsQuery = { __typename?: 'Query', assistants?: { __typename?: 'AssistantsConnection', nodes: Array<{ __typename?: 'Assistant', id: number }> } | null };

export type CreateAssistantMutationVariables = Exact<{
  assistant: AssistantInput;
}>;


export type CreateAssistantMutation = { __typename?: 'Mutation', createAssistant?: { __typename?: 'CreateAssistantPayload', assistant?: { __typename?: 'Assistant', id: number, identifier: any, status: AssistantStatus, info: any } | null } | null };

export type UpdateAssistantMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  patch: AssistantPatch;
}>;


export type UpdateAssistantMutation = { __typename?: 'Mutation', updateAssistant?: { __typename?: 'UpdateAssistantPayload', assistant?: { __typename?: 'Assistant', id: number, identifier: any, status: AssistantStatus, info: any } | null } | null };

export type DeleteAssistantMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteAssistantMutation = { __typename?: 'Mutation', deleteAssistant?: { __typename?: 'DeleteAssistantPayload', assistant?: { __typename?: 'Assistant', id: number } | null } | null };

export type ArchiveAssistantMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ArchiveAssistantMutation = { __typename?: 'Mutation', archiveAssistant?: { __typename?: 'ArchiveAssistantPayload', assistant?: { __typename?: 'Assistant', id: number } | null } | null };

export type RecommendAssistantConfigurationMutationVariables = Exact<{
  assistantId: Scalars['Int']['input'];
}>;


export type RecommendAssistantConfigurationMutation = { __typename?: 'Mutation', recommendAssistantConfiguration?: { __typename?: 'AssistantConfigurationPayload', info: any } | null };

export type ListChatIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListChatIdsQuery = { __typename?: 'Query', chats?: { __typename?: 'ChatsConnection', nodes: Array<{ __typename?: 'Chat', id: number }> } | null };

export type StartPreviewChatMutationVariables = Exact<{
  assistantIdentifier: Scalars['UUID']['input'];
}>;


export type StartPreviewChatMutation = { __typename?: 'Mutation', startPreviewChat?: { __typename?: 'BasicChatPayload', chat?: { __typename?: 'BasicChatInfo', id?: number | null, status?: ChatStatus | null, messages?: any | null } | null } | null };

export type StartChatMutationVariables = Exact<{
  assistantIdentifier: Scalars['UUID']['input'];
}>;


export type StartChatMutation = { __typename?: 'Mutation', startChat?: { __typename?: 'BasicChatPayload', chat?: { __typename?: 'BasicChatInfo', id?: number | null, status?: ChatStatus | null, messages?: any | null } | null } | null };

export type CompletePreviewChatMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  assistantIdentifier: Scalars['UUID']['input'];
  userMessage: Scalars['String']['input'];
}>;


export type CompletePreviewChatMutation = { __typename?: 'Mutation', completePreviewChat?: { __typename?: 'BasicChatPayload', chat?: { __typename?: 'BasicChatInfo', id?: number | null, messages?: any | null } | null } | null };

export type CompleteChatMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  assistantIdentifier: Scalars['UUID']['input'];
  userMessage: Scalars['String']['input'];
}>;


export type CompleteChatMutation = { __typename?: 'Mutation', completeChat?: { __typename?: 'BasicChatPayload', chat?: { __typename?: 'BasicChatInfo', id?: number | null, messages?: any | null } | null } | null };

export type CompleteMessagesMutationVariables = Exact<{
  messages: Scalars['String']['input'];
}>;


export type CompleteMessagesMutation = { __typename?: 'Mutation', completeMessages?: { __typename?: 'BasicMessages', messages: any } | null };

export type FinishChatMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  assistantIdentifier: Scalars['UUID']['input'];
}>;


export type FinishChatMutation = { __typename?: 'Mutation', finishChat?: { __typename?: 'BasicChatPayload', chat?: { __typename?: 'BasicChatInfo', id?: number | null, status?: ChatStatus | null } | null } | null };

export type UpdateChatMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  patch: ChatPatch;
}>;


export type UpdateChatMutation = { __typename?: 'Mutation', updateChat?: { __typename?: 'UpdateChatPayload', chat?: { __typename?: 'Chat', id: number } | null } | null };

export type DeleteChatMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteChatMutation = { __typename?: 'Mutation', deleteChat?: { __typename?: 'DeleteChatPayload', chat?: { __typename?: 'Chat', id: number } | null } | null };

export type CreateSubscriberMutationVariables = Exact<{
  info: Scalars['JSON']['input'];
}>;


export type CreateSubscriberMutation = { __typename?: 'Mutation', createSubscriber?: { __typename?: 'CreateSubscriberPayload', clientMutationId?: string | null } | null };

export type ListAssistantsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAssistantsQuery = { __typename?: 'Query', assistants?: { __typename?: 'AssistantsConnection', nodes: Array<{ __typename?: 'Assistant', id: number, status: AssistantStatus, info: any, chats: { __typename?: 'ChatsConnection', totalCount: number } }> } | null };

export type AssistantQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type AssistantQuery = { __typename?: 'Query', assistant?: { __typename?: 'Assistant', id: number, identifier: any, status: AssistantStatus, info: any } | null };

export type ChatsForAssistantQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ChatsForAssistantQuery = { __typename?: 'Query', chats?: { __typename?: 'ChatsConnection', nodes: Array<{ __typename?: 'Chat', id: number, createdAt: any, type?: ChatType | null, status: ChatStatus, messages: any, summary?: string | null, results?: any | null, sentiment?: string | null, keyTopics?: string | null }> } | null };

export type AssistantByIdentifierQueryVariables = Exact<{
  identifier: Scalars['UUID']['input'];
}>;


export type AssistantByIdentifierQuery = { __typename?: 'Query', assistantByIdentifier?: { __typename?: 'BasicAssistantInfo', identifier?: any | null, info?: any | null } | null };


export const ListAssistantIdsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListAssistantIds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<ListAssistantIdsQuery, ListAssistantIdsQueryVariables>;
export const CreateAssistantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateAssistant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistant"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssistantInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createAssistant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"assistant"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistant"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"info"}}]}}]}}]}}]} as unknown as DocumentNode<CreateAssistantMutation, CreateAssistantMutationVariables>;
export const UpdateAssistantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateAssistant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patch"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AssistantPatch"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAssistant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"patch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patch"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"info"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateAssistantMutation, UpdateAssistantMutationVariables>;
export const DeleteAssistantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAssistant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAssistant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<DeleteAssistantMutation, DeleteAssistantMutationVariables>;
export const ArchiveAssistantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ArchiveAssistant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveAssistant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<ArchiveAssistantMutation, ArchiveAssistantMutationVariables>;
export const RecommendAssistantConfigurationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RecommendAssistantConfiguration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistantId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"recommendAssistantConfiguration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"assistantId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistantId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"info"}}]}}]}}]} as unknown as DocumentNode<RecommendAssistantConfigurationMutation, RecommendAssistantConfigurationMutationVariables>;
export const ListChatIdsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListChatIds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<ListChatIdsQuery, ListChatIdsQueryVariables>;
export const StartPreviewChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartPreviewChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startPreviewChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"assistantIdentifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode<StartPreviewChatMutation, StartPreviewChatMutationVariables>;
export const StartChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"StartChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"startChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"assistantIdentifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode<StartChatMutation, StartChatMutationVariables>;
export const CompletePreviewChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CompletePreviewChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userMessage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completePreviewChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"assistantIdentifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"userMessage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userMessage"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode<CompletePreviewChatMutation, CompletePreviewChatMutationVariables>;
export const CompleteChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CompleteChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userMessage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completeChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"assistantIdentifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"userMessage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userMessage"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]}}]} as unknown as DocumentNode<CompleteChatMutation, CompleteChatMutationVariables>;
export const CompleteMessagesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"completeMessages"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"messages"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completeMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"messages"},"value":{"kind":"Variable","name":{"kind":"Name","value":"messages"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messages"}}]}}]}}]} as unknown as DocumentNode<CompleteMessagesMutation, CompleteMessagesMutationVariables>;
export const FinishChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FinishChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"finishChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"assistantIdentifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assistantIdentifier"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<FinishChatMutation, FinishChatMutationVariables>;
export const UpdateChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patch"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChatPatch"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"patch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patch"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateChatMutation, UpdateChatMutationVariables>;
export const DeleteChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chat"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<DeleteChatMutation, DeleteChatMutationVariables>;
export const CreateSubscriberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSubscriber"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"info"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JSON"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSubscriber"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"info"},"value":{"kind":"Variable","name":{"kind":"Name","value":"info"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clientMutationId"}}]}}]}}]} as unknown as DocumentNode<CreateSubscriberMutation, CreateSubscriberMutationVariables>;
export const ListAssistantsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListAssistants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"info"}},{"kind":"Field","name":{"kind":"Name","value":"chats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalCount"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ListAssistantsQuery, ListAssistantsQueryVariables>;
export const AssistantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Assistant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"info"}}]}}]}}]} as unknown as DocumentNode<AssistantQuery, AssistantQueryVariables>;
export const ChatsForAssistantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ChatsForAssistant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chats"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"condition"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"assistantId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"messages"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"results"}},{"kind":"Field","name":{"kind":"Name","value":"sentiment"}},{"kind":"Field","name":{"kind":"Name","value":"keyTopics"}}]}}]}}]}}]} as unknown as DocumentNode<ChatsForAssistantQuery, ChatsForAssistantQueryVariables>;
export const AssistantByIdentifierDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AssistantByIdentifier"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"identifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assistantByIdentifier"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"identifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"identifier"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"info"}}]}}]}}]} as unknown as DocumentNode<AssistantByIdentifierQuery, AssistantByIdentifierQueryVariables>;
import {Step, StepType} from "../assistantTypes.ts";
import {ReactNode} from "react";
import {MultipleChoiceResult, NPSResult, RatingResult, Result} from "../resultTypes.ts";
import MultipleChoiceChart from "./MultipleChoiceChart.tsx";
import NPSChart from "./NPSChart.tsx";
import RatingChart from "./RatingChart.tsx";
import { Card } from "antd";

interface ChartProps {
    step: Step,
    results?: Result[]
}

function Chart({
                   step,
                   results
}: ChartProps): ReactNode {
    if(step.type === StepType.NPS) {
        return <NPSChart results={results as NPSResult[]}/>
    }
    if(step.type === StepType.Rating){
        return <RatingChart results={results as RatingResult[]}/>
    }
    if(step.type === StepType.MultipleChoice) {
        return <MultipleChoiceChart options={step.options as String[]} results={results as MultipleChoiceResult[]}/>
    }
    // unsupported currently
    return <Card
        className="h-full overflow-hidden"
        title="Coming soon"
    >
        Coming soon
    </Card>;
}

export default Chart;

import {Input} from "antd";
import {Assistant} from "../../../../gql/graphql.ts";
import Avatar from "../../../../components/Avatar/Avatar.tsx";
import {AssistantInfo} from "../assistantTypes.ts";
import classNames from "classnames";

interface ConfigureIdentityProps {
  assistant: Pick<Assistant, 'info'>;
  updateAssistantInfo: (info: Partial<AssistantInfo>) => void;
  editable: boolean;
}

export default function ConfigureIdentity({ assistant, updateAssistantInfo, editable }: ConfigureIdentityProps) {
  return (
    <div className="flex flex-col items-center">
      <Avatar
        className="mb-2 ml-2"
        avatar={(assistant.info as AssistantInfo | undefined)?.avatar}
        onChange={(avatar) => updateAssistantInfo({ avatar })}
        disabled={!editable}
      />
      <Input
        className={classNames(
          "font-bold text-center",
          editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]",
        )}
        variant="borderless"
        placeholder="Name (e.g. Alice)"
        value={(assistant.info as AssistantInfo | undefined)?.name}
        onChange={(event) => updateAssistantInfo({ name: event.target.value })}
        disabled={!editable}
      />
      <Input
        className={classNames(
          "!text-gray-500 text-center",
          editable && "focus:bg-gray-100 hover:bg-gray-100",
        )}
        variant="borderless"
        placeholder="Role (e.g. Recruiting Assistant)"
        value={(assistant.info as AssistantInfo | undefined)?.role}
        onChange={(event) => updateAssistantInfo({ role: event.target.value })}
        disabled={!editable}
      />
    </div>
  );
}

import type {HTMLProps} from "react";
import classNames from "classnames";
import {BulbFilled} from "@ant-design/icons";

import './QuerriIcon.css';

interface QuerriIconProps extends HTMLProps<HTMLDivElement> {
  color?: string;
}

function QuerriIcon({ color, className, ...otherProps }: QuerriIconProps) {
  return <div
    className={classNames(
      "querri-icon bg-primary",
      className,
    )}
    style={{
      backgroundColor: color,
    }}
    {...otherProps}
  >
    <BulbFilled className="mr-2" />
    Querii
  </div>
}

export default QuerriIcon;

import {useCallback} from 'react';
import {Button, Collapse, Input, Space, message} from 'antd';
import QuestionTooltip from '../../../components/tooltips/QuestionTooltip';
import {Assistant} from "../../../gql/graphql.ts";
import {GA_EVENT, GoogleAnalytics} from "../../../config/googleAnalytics.ts";

interface ShareAssistantProps {
  assistant: Pick<Assistant, 'identifier'>;
}

const ShareAssistant = ({ assistant }: ShareAssistantProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const link = `${window.location.origin}/app/chat/${assistant.identifier}`;

  const copyLink = useCallback(() => {
    GoogleAnalytics.event(GA_EVENT.COPY_LINK);
    navigator.clipboard.writeText(link);
    messageApi.success('Link copied to clipboard');
  }, [link, messageApi]);

  return (
    <>
      {contextHolder}
      <Collapse
        accordion
        defaultActiveKey="link"
        items={[
          {
            key: 'link',
            label: 'Share the Link',
            extra: QuestionTooltip({textToShow: "This is a link you can send to your clients, so they can start chatting with your assistant."}),
            children: (
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Input
                  readOnly
                  value={link}
                  onClick={copyLink}
                />
                <Button
                  type="primary"
                  onClick={copyLink}
                >
                  Copy
                </Button>
              </Space.Compact>
            ),
          },
          {
            key: 'email',
            label: 'Send an Email',
            children: <div>Coming Soon</div>,
          },
        ]}
      />
    </>
  );
};

export default ShareAssistant;

import React, {PropsWithChildren, ReactElement} from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import DebounceLink from 'apollo-link-debounce';
import {Auth} from "aws-amplify";

import {config} from "../config/config.ts";

const DEFAULT_DEBOUNCE_TIME_MS = 200;
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIME_MS);

const httpLink = createHttpLink({
  uri: config.api.baseURL,
});

const authLink = setContext(async (_, { headers }) => {
  const newHeaders = { ...headers };
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    if (token != null) newHeaders.authorization = `Bearer ${token}`;
  } catch (e) {
    // Do nothing, user is not logged in
  }
  // Return the headers to the context so httpLink can read them
  return {
    headers: newHeaders,
  };
});

const client = new ApolloClient({
  link: authLink.concat(debounceLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const APIProvider = ({ children }: PropsWithChildren): ReactElement => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default APIProvider;

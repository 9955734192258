import FeatureSection from "./components/FeatureSection.tsx";
import {
  ChatBubbleLeftRightIcon,
  ClockIcon,
  EnvelopeIcon,
  LanguageIcon,
  LightBulbIcon,
  LinkIcon,
  PuzzlePieceIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  SparklesIcon,
  WindowIcon,
} from "@heroicons/react/20/solid";
import Header from "./components/Header.tsx";

import InfinitelyScalable from './resources/InfinitelyScalable.png';
import CompletelyEffortless from './resources/CompletelyEffortless.png';
import NewsLetterSection from "./components/NewsLetterSection.tsx";
import GetStartedButton from "./components/GetStartedButton.tsx";
import SegmentedSection from "./components/SegmentedSection.tsx";
import Highlights from "./components/Highlights.tsx";
import QuerriIcon from "../components/QuerriIcon/QuerriIcon.tsx";

export default function Home() {
    return (
      <div className="bg-white">
        <Header/>

        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#1677FF] to-[#00d4ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                AI-powered conversational surveys
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Engage participants in dynamic, interactive conversations that feel natural and intuitive. &nbsp;
                <b>No more boring forms – just meaningful dialogue!</b>
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <GetStartedButton>
                  Get started free
                </GetStartedButton>
                <a href="#chat-or-survey" className="text-sm font-semibold leading-6 text-gray-900">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#1677FF] to-[#00d4ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>

        <FeatureSection
          id="chat-or-survey"
          highlight="Chat or survey?"
          title="Get the best of both worlds"
          description="Wouldn't it be great if you could talk to every single user, customer, or employee? Now you can."
          content={
            <table
              className="text-lg bg-gray-100 text-gray-700 text-center leading-10 lg:mt-12 border-collapse [&_th]:border-solid [&_th]:border-2 [&_th]:border-white [&_td]:border-solid [&_td]:border-2 [&_td]:border-white rounded-3xl overflow-clip">
              <tr className="*:font-semibold">
                <th></th>
                <th>Quality</th>
                <th>Scalability</th>
              </tr>
              <tr>
                <td>Conversations</td>
                <td>⭐⭐️⭐️️</td>
                <td>⭐</td>
              </tr>
              <tr>
                <td>Surveys</td>
                <td>⭐</td>
                <td>⭐⭐⭐</td>
              </tr>
              <tr className="bg-gray-200">
                <td className="py-3">
                  <QuerriIcon className="max-w-fit mx-auto"/>
                </td>
                <td>⭐⭐⭐</td>
                <td>⭐⭐⭐</td>
              </tr>
            </table>
          }
        />

        <SegmentedSection
          subtitle="Get started in minutes"
          title="Just tell us what you need"
          description="Stop wasting time crafting the perfect survey. Let Querii do the heavy lifting."
          segments={[
            {
              name: 'Align',
              description: 'Align on a goal and a good flow for the conversation.',
              content: (
                <div>
                  <p className="text-lg leading-7 text-gray-600 mb-8">
                    Align on a goal and a good flow for the conversation, however you like.
                  </p>
                  <Highlights highlights={[
                    {
                      name: 'Chat with Querii',
                      description: 'Explain your goal and discuss the best way to achieve it together.',
                      icon: SparklesIcon,
                    },
                    {
                      name: 'Pick a template',
                      description: 'Choose from a variety of templates to get started quickly.',
                      icon: RectangleStackIcon,
                    },
                    {
                      name: 'Start from scratch',
                      description: 'Have the perfect idea in mind? Start from scratch and build your own flow.',
                      icon: LightBulbIcon,
                    }
                  ]} highlightClassName="bg-white shadow-sm" />
                </div>
              ),
            },
            {
              name: 'Share',
              description: 'Share a link, send an email, embed it on your website, etc.',
              content: (
                <div>
                  <p className="text-lg leading-7 text-gray-600  mb-8">
                    Make your Querii available to your audience in the way that suits you best.
                  </p>
                  <Highlights highlights={[
                    {
                      name: 'Share a link',
                      description: 'Keep things simple and let your users start the conversation.',
                      icon: LinkIcon,
                    },
                    {
                      name: 'Send an email',
                      description: 'Send personalized emails to reach your audience.',
                      icon: EnvelopeIcon,
                    },
                    {
                      name: 'Embed on your website',
                      description: 'Integrate Querii directly into your website.',
                      icon: WindowIcon,
                    }
                  ]} highlightClassName="bg-white shadow-sm" />
                </div>
              ),
            },
            {
              name: 'Analyse',
              description: 'Let your users talk to Querii and he will report back.',
              content: (
                <div>
                  <p className="text-lg leading-7 text-gray-600  mb-8">
                    It has never been easier to get insights from your audience.
                  </p>
                  <Highlights highlights={[
                    {
                      name: 'Ask Querii',
                      description: 'Have a question in mind? Just ask Querii.',
                      icon: SparklesIcon,
                    },
                    {
                      name: 'Explore analytics',
                      description: 'Discover sentiments, trends, key highlights and more.',
                      icon: EnvelopeIcon,
                    },
                    {
                      name: 'Analyze conversations',
                      description: 'Explore conversation-specific summaries and insights.',
                      icon: WindowIcon,
                    }
                  ]} highlightClassName="bg-white shadow-sm" />
                </div>
              ),
            }
          ]}
        />

        <FeatureSection
          highlight="AI-enabled"
          title="As scalable as a survey"
          description="Always ready for a chat, no matter how many people want to talk."
          features={[
            {
              name: '24/7 Availability',
              description: 'Querii is available round the clock, enabling global interactions without time zone constraints.',
              icon: ClockIcon,
            },
            {
              name: 'Simultaneous chats',
              description: 'Gather information from numerous users simultaneous, making your process highly scalable.',
              icon: ChatBubbleLeftRightIcon,
            },
            {
              name: '95+ Languages',
              description: 'Break language barriers effortlessly – Querii is fluent in over 95 languages, ensuring global accessibility and engagement.',
              icon: LanguageIcon,
            },
          ]}
          image={{
            src: InfinitelyScalable,
            alt: 'Examples of assistants',
          }}
        />

        <FeatureSection
          left
          highlight="Completely effortless"
          title="Your personal survey assistant"
          description="Just tell Querii what to do, like you would a colleague."
          features={[
            {
              name: 'True understanding',
              description: 'Explain Querii what you’d like to get out of the conversation and he’ll figure out how to do that, adapting to dynamic conversation.',
              icon: LightBulbIcon,
            },
            {
              name: 'Industry knowledge',
              description: 'Whether in marketing, product development, or other fields, Querii has the right background to get the most out of the conversation.',
              icon: PuzzlePieceIcon,
            },
            {
              name: 'Focus on what matters',
              description: 'Stop wasting time crafting the perfect survey. Get results in seconds instead of hours.',
              icon: RocketLaunchIcon,
            },
          ]}
          image={{
            src: CompletelyEffortless,
            alt: 'Examples conversation with a hiring candidate',
          }}
        />

        <NewsLetterSection/>
      </div>
    )
}

import {Input, InputProps} from "antd";
import {SearchOutlined} from "@ant-design/icons";

interface SearchInputProps extends InputProps {}

export default function SearchInput(props: SearchInputProps) {
  return (
    <Input
      style={{ maxWidth: 300 }}
      placeholder="Search"
      prefix={<SearchOutlined />}
      allowClear
      {...props}
    />
  );
};

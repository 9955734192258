import {MutationHookOptions, MutationTuple} from '@apollo/client/react/types/types';

import {gql, useMutation} from '@apollo/client';

import {
  CompleteChatMutation,
  CompleteChatMutationVariables, CompletePreviewChatMutation,
  DeleteChatMutation,
  DeleteChatMutationVariables,
  FinishChatMutation,
  FinishChatMutationVariables,
  ListChatIdsQuery,
  StartChatMutation,
  StartChatMutationVariables,
  StartPreviewChatMutation,
  StartPreviewChatMutationVariables,
  UpdateChatMutation,
  UpdateChatMutationVariables,
    CompleteMessagesMutation,
    CompleteMessagesMutationVariables
} from '../gql/graphql';
import {AlteredMutationHookOptions, useAlteredMutation} from "./helpers/apollo.helpers.ts";
import {GA_EVENT, GoogleAnalytics} from "../config/googleAnalytics.ts";

//region List

const LIST_CHAT_IDS_QUERY = gql`
    query ListChatIds {
        chats {
            nodes {
                id
            }
        }
    }
`;

//endregion

//region Start

export type StartChatData = StartChatMutation['startChat'];
export type StartChatMutationTuple = MutationTuple<StartChatData, StartChatMutationVariables>;

export const useStartPreviewChat = (
  options?: AlteredMutationHookOptions<
    StartPreviewChatMutation,
    StartPreviewChatMutationVariables,
    StartChatData
  >,
): StartChatMutationTuple => {
    return useAlteredMutation<
      StartPreviewChatMutation,
      StartPreviewChatMutationVariables,
      StartChatData
    >(
      gql`
          mutation StartPreviewChat($assistantIdentifier: UUID!) {
              startPreviewChat(
                  input: {
                      assistantIdentifier: $assistantIdentifier,
                  }
              ) {
                  chat {
                      id
                      status
                      messages
                  }
              }
          }
      `,
      {
        mapGQLDataToAlteredData: (data) => data.startPreviewChat,
        mapAlteredDataToGQLData: (data) => ({
          startPreviewChat: data,
        }),
        update: (cache, { data }) => {
          const startedChat = data?.startPreviewChat?.chat;
          if (startedChat == null) return;
          // TODO: Remove any
          const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
          const newChats = [...(chats?.chats?.nodes ?? []), startedChat];
          cache.writeQuery<ListChatIdsQuery>({
            query: LIST_CHAT_IDS_QUERY,
            data: { chats: { nodes: newChats } },
          });
        },
        ...options,
      },
    );
};

export const useStartChat = (
  options?: AlteredMutationHookOptions<
    StartPreviewChatMutation,
    StartPreviewChatMutationVariables,
    StartChatData
  >,
): StartChatMutationTuple =>
useAlteredMutation<
  StartChatMutation,
  StartChatMutationVariables,
  StartChatData
>(
  gql`
      mutation StartChat($assistantIdentifier: UUID!) {
          startChat(
              input: {
                  assistantIdentifier: $assistantIdentifier,
              }
          ) {
              chat {
                  id
                  status
                  messages
              }
          }
      }
  `,
  {
    mapGQLDataToAlteredData: (data) => data.startChat,
    mapAlteredDataToGQLData: (data) => ({
      startChat: data,
    }),
    update: (cache, { data }) => {
      const startedChat = data?.startChat?.chat;
      if (startedChat == null) return;
      // TODO: Remove any
      const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
      const newChats = [...(chats?.chats?.nodes ?? []), startedChat];
      cache.writeQuery<ListChatIdsQuery>({
        query: LIST_CHAT_IDS_QUERY,
        data: { chats: { nodes: newChats } },
      });
    },
    ...options,
  },
);

//endregion

//region Complete

export type CompleteChatData = CompleteChatMutation['completeChat'];
export type CompleteChatMutationTuple = MutationTuple<CompleteChatData, CompleteChatMutationVariables>;

export const useCompletePreviewChatMutation = (
  options?: AlteredMutationHookOptions<
    CompletePreviewChatMutation,
    CompleteChatMutationVariables,
    CompleteChatData
  >,
): CompleteChatMutationTuple =>
useAlteredMutation<
  CompletePreviewChatMutation,
  CompleteChatMutationVariables,
  CompleteChatData
>(
  gql`
      mutation CompletePreviewChat($id: Int!, $assistantIdentifier: UUID!, $userMessage: String!) {
          completePreviewChat(input: { id: $id, assistantIdentifier: $assistantIdentifier, userMessage: $userMessage }) {
              chat {
                  id
                  messages
              }
          }
      }
  `,
  {
    mapGQLDataToAlteredData: (data) => data.completePreviewChat,
    mapAlteredDataToGQLData: (data) => ({
      completePreviewChat: data,
    }),
    update: (cache, { data }) => {
      const completedChat = data?.completePreviewChat?.chat;
      if (completedChat == null) return;
      const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
      const newChats = chats?.chats?.nodes.map((t: any) =>
        t.id === completedChat.id ? { ...t, ...completedChat } : t,
      );
      cache.writeQuery<ListChatIdsQuery>({
        query: LIST_CHAT_IDS_QUERY,
        data: { chats: { nodes: newChats ?? [] } },
      });
    },
    ...options,
    onCompleted: (...params) => {
      GoogleAnalytics.event(GA_EVENT.SEND_PREVIEW_CHAT_MESSAGE);
      if (options?.onCompleted) options.onCompleted(...params);
    }
  },
);

export const useCompleteChatMutation = (
  options?: AlteredMutationHookOptions<
    CompleteChatMutation,
    CompleteChatMutationVariables,
    CompleteChatData
  >,
): CompleteChatMutationTuple =>
useAlteredMutation<
  CompleteChatMutation,
  CompleteChatMutationVariables,
  CompleteChatData
>(
  gql`
      mutation CompleteChat($id: Int!, $assistantIdentifier: UUID!, $userMessage: String!) {
          completeChat(input: { id: $id, assistantIdentifier: $assistantIdentifier, userMessage: $userMessage }) {
              chat {
                  id
                  messages
              }
          }
      }
  `,
  {
    mapGQLDataToAlteredData: (data) => data.completeChat,
    mapAlteredDataToGQLData: (data) => ({
      completeChat: data,
    }),
    update: (cache, { data }) => {
      const completedChat = data?.completeChat?.chat;
      if (completedChat == null) return;
      const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
      const newChats = chats?.chats?.nodes.map((t: any) =>
        t.id === completedChat.id ? { ...t, ...completedChat } : t,
      );
      cache.writeQuery<ListChatIdsQuery>({
        query: LIST_CHAT_IDS_QUERY,
        data: { chats: { nodes: newChats ?? [] } },
      });
    },
    ...options,
  },
);

//endregion

//region completeMessages
//
const COMPLETE_MESSAGES_MUTATION = gql`
    mutation completeMessages($messages: String!) {
        completeMessages(input: {messages: $messages }) {
            messages
        }
    }
`;

export const useCompleteMessagesMutation = (
    options?: MutationHookOptions<CompleteMessagesMutation, CompleteMessagesMutationVariables>,
) =>
    useMutation<CompleteMessagesMutation, CompleteMessagesMutationVariables>(COMPLETE_MESSAGES_MUTATION, {
        ...options,
    });


//endregion

//region Finish

const FINISH_CHAT_MUTATION = gql`
    mutation FinishChat($id: Int!, $assistantIdentifier: UUID!) {
        finishChat(input: { id: $id, assistantIdentifier: $assistantIdentifier }) {
            chat {
                id
                status
            }
        }
    }
`;
export const useFinishChatMutation = (
  options?: MutationHookOptions<FinishChatMutation, FinishChatMutationVariables>,
) =>
  useMutation<FinishChatMutation, FinishChatMutationVariables>(FINISH_CHAT_MUTATION, {
    finish: (cache, { data }) => {
      const finishedChat = data?.finishChat?.chat;
      if (finishedChat == null) return;
      const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
      const newChats = chats?.chats?.nodes.map((t: any) =>
        t.id === finishedChat.id ? { ...t, ...finishedChat } : t,
      );
      cache.writeQuery<ListChatIdsQuery>({
        query: LIST_CHAT_IDS_QUERY,
        data: { chats: { nodes: newChats ?? [] } },
      });
    },
    ...options,
  });

//endregion

//region Update

const UPDATE_CHAT_MUTATION = gql`
    mutation UpdateChat($id: Int!, $patch: ChatPatch!) {
        updateChat(input: { id: $id, patch: $patch }) {
            chat {
                id
            }
        }
    }
`;
export const useUpdateChatMutation = (
  options?: MutationHookOptions<UpdateChatMutation, UpdateChatMutationVariables>,
) =>
  useMutation<UpdateChatMutation, UpdateChatMutationVariables>(UPDATE_CHAT_MUTATION, {
    update: (cache, { data }) => {
      const updatedChat = data?.updateChat?.chat;
      if (updatedChat == null) return;
      const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
      const newChats = chats?.chats?.nodes.map((t: any) =>
        t.id === updatedChat.id ? updatedChat : t,
      );
      cache.writeQuery<ListChatIdsQuery>({
        query: LIST_CHAT_IDS_QUERY,
        data: { chats: { nodes: newChats ?? [] } },
      });
    },
    ...options,
  });

//endregion

//region Delete

const DELETE_DISCOVERY_MUTATION = gql`
    mutation DeleteChat($id: Int!) {
        deleteChat(input: { id: $id }) {
            chat {
                id
            }
        }
    }
`;

export const useDeleteChatMutation = (
  options?: MutationHookOptions<DeleteChatMutation, DeleteChatMutationVariables>,
) =>
  useMutation<DeleteChatMutation, DeleteChatMutationVariables>(DELETE_DISCOVERY_MUTATION, {
    update: (cache, { data }) => {
      const deletedChatId = data?.deleteChat?.chat?.id;
      if (deletedChatId == null) return;
      const chats = cache.readQuery<ListChatIdsQuery>({ query: LIST_CHAT_IDS_QUERY });
      const newChats = chats?.chats?.nodes.filter((t) => t.id !== deletedChatId);
      cache.writeQuery<ListChatIdsQuery>({
        query: LIST_CHAT_IDS_QUERY,
        data: { chats: { nodes: newChats ?? [] } },
      });
    },
    ...options,
  });

//endregion

import {useNavigate} from "react-router-dom";
import {Dropdown, Tooltip} from "antd";
import {EditOutlined, FileOutlined, StarOutlined} from "@ant-design/icons";
import CreateButton from "../../buttons/CreateButton.tsx";
import {useCreateAssistant} from "../../../api/assistant.api.ts";
import {ASSISTANT_TEMPLATES} from "./assistantTemplates.ts";
import {GA_EVENT, GoogleAnalytics} from "../../../config/googleAnalytics.ts";
import {useCallback} from "react";

function CreateAssistantButton() {
  const navigate = useNavigate();
  const [createAssistant] = useCreateAssistant();

  const onCreateCompleted = useCallback((assistantId: number, method: string) => {
    GoogleAnalytics.event(GA_EVENT.CREATE_ASSISTANT, { method })
    navigate(`/app/assistants/${assistantId}`);
  }, [navigate]);
  
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'talk-to-querii',
            icon: <StarOutlined />,
            label: <Tooltip title="Coming Soon">Talk to Querii</Tooltip>,
            disabled: true,
          },
          {
            key: 'from-template',
            icon: <FileOutlined />,
            label: 'Use a template',
            children: ASSISTANT_TEMPLATES.map((template) => ({
              key: template.name,
              label: (
                <div className="flex flex-col max-w-[350px]">
                  <div className="font-semibold">{template.name}</div>
                  <div className="text-sm text-gray-500">{template.description}</div>
                </div>
              ),
              onClick: async () => {
                await createAssistant({
                  variables: {
                    assistant: {
                      info: template.assistantInfo,
                    },
                  },
                  onCompleted: (result) => {
                    const assistantId = result.createAssistant?.assistant?.id;
                    if (!assistantId) return;
                    const method = `template:${template.name}`;
                    onCreateCompleted(assistantId, method);
                  },
                })
              },
            })),
          },
          {
            key: 'from-scratch',
            icon: <EditOutlined />,
            label: 'Start from scratch',
            onClick: async () => {
              await createAssistant({
                variables: {
                  assistant: {}
                },
                onCompleted: (result) => {
                  const assistantId = result.createAssistant?.assistant?.id;
                  if (!assistantId) return;
                  onCreateCompleted(assistantId, 'from-scratch');
                },
              })
            },
          },
        ],
      }}
      placement="bottomCenter"
    >
      <CreateButton>
        Create Assistant
      </CreateButton>
    </Dropdown>
  )
}

export default CreateAssistantButton;
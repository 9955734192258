import type { MenuProps } from 'antd';
import {Button, Menu} from 'antd'
import QuerriIcon from '../../components/QuerriIcon/QuerriIcon.tsx';

import './Sidebar.css';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {useNavigate} from "react-router-dom";

const MENU_ITEMS: MenuProps['items'] = [
  {
    key: 'assistants',
    label: 'Assistants',
  },
];

const Sidebar = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);
    const navigate = useNavigate();

    return (
    <div className="sidebar">
      <div
          className="sidebar-icon"
          onClick={() => navigate('/app')}
      >
       <QuerriIcon/>
      </div>
      <Menu
        defaultSelectedKeys={['assistants']}
        mode="inline"
        items={MENU_ITEMS}
        onClick={({ key }) => navigate(`/app/${key}`)}
      />
      <Button
        className="logout-button"
        danger
        onClick={() => signOut()}
      >
        Log out
      </Button>
    </div>
  );
};

export default Sidebar;

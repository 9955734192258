//region Create

import {MutationHookOptions} from "@apollo/client/react/types/types";
import {
    CreateSubscriberMutation,
    CreateSubscriberMutationVariables,
} from "../gql/graphql.ts";
import {gql, useMutation} from "@apollo/client";

export const useCreateSubscriber = (
    options?: MutationHookOptions<CreateSubscriberMutation, CreateSubscriberMutationVariables>,
) => {

    const [mutation, result] = useMutation<CreateSubscriberMutation, CreateSubscriberMutationVariables>(
        gql`
            mutation CreateSubscriber($info: JSON!) {
                createSubscriber(input: {info: $info}) {
                    clientMutationId
                }
            }
        `
    , options);
    return [mutation, result] as const;
};

//endregion

import type { MutationHookOptions } from '@apollo/client/react/types/types';
import { gql, useMutation } from '@apollo/client';
import type {
  ArchiveAssistantMutation, ArchiveAssistantMutationVariables,
  CreateAssistantMutation,
  CreateAssistantMutationVariables,
  DeleteAssistantMutation,
  DeleteAssistantMutationVariables,
  ListAssistantIdsQuery,
    RecommendAssistantConfigurationMutation,
    RecommendAssistantConfigurationMutationVariables,
  UpdateAssistantMutation,
  UpdateAssistantMutationVariables,
} from '../gql/graphql';

//region List

const LIST_ASSISTANT_IDS_QUERY = gql`
    query ListAssistantIds {
        assistants {
            nodes {
                id
            }
        }
    }
`;

//endregion

//region Create

export const useCreateAssistant = (
  options?: MutationHookOptions<CreateAssistantMutation, CreateAssistantMutationVariables>,
) => {
    const [mutation, result] = useMutation<CreateAssistantMutation, CreateAssistantMutationVariables>(
      gql`
          mutation CreateAssistant($assistant: AssistantInput!) {
              createAssistant(
                  input: {
                      assistant: $assistant
                  }
              ) {
                  assistant {
                      id
                      identifier
                      status
                      info
                  }
              }
          }
      `,
      {
        update: (cache, { data }) => {
          const createdAssistant = data?.createAssistant?.assistant;
          if (createdAssistant == null) return;
          // TODO: Remove any
          const assistants = cache.readQuery<ListAssistantIdsQuery>({ query: LIST_ASSISTANT_IDS_QUERY });
          const newAssistants = [...(assistants?.assistants?.nodes ?? []), createdAssistant];
          cache.writeQuery<ListAssistantIdsQuery>({
            query: LIST_ASSISTANT_IDS_QUERY,
            data: { assistants: { nodes: newAssistants } },
          });
        },
        ...options,
      },
    );
  return [mutation, { ...result, data: result.data?.createAssistant }] as const;
};

//endregion

//region Update

const UPDATE_ASSISTANT_MUTATION = gql`
    mutation UpdateAssistant($id: Int!, $patch: AssistantPatch!) {
        updateAssistant(input: { id: $id, patch: $patch }) {
            assistant {
                id
                identifier
                status
                info
            }
        }
    }
`;
export const useUpdateAssistantMutation = (
  options?: MutationHookOptions<UpdateAssistantMutation, UpdateAssistantMutationVariables>,
) =>
  useMutation<UpdateAssistantMutation, UpdateAssistantMutationVariables>(UPDATE_ASSISTANT_MUTATION, {
    update: (cache, { data }) => {
      const updatedAssistant = data?.updateAssistant?.assistant;
      if (updatedAssistant == null) return;
      const assistants = cache.readQuery<ListAssistantIdsQuery>({ query: LIST_ASSISTANT_IDS_QUERY });
      const newAssistants = assistants?.assistants?.nodes.map((t: any) =>
        t.id === updatedAssistant.id ? updatedAssistant : t,
      );
      cache.writeQuery<ListAssistantIdsQuery>({
        query: LIST_ASSISTANT_IDS_QUERY,
        data: { assistants: { nodes: newAssistants ?? [] } },
      });
    },
    ...options,
  });

//endregion

//region Delete

const DELETE_ASSISTANT_MUTATION = gql`
    mutation DeleteAssistant($id: Int!) {
        deleteAssistant(input: { id: $id }) {
            assistant {
                id
            }
        }
    }
`;

export const useDeleteAssistantMutation = (
  options?: MutationHookOptions<DeleteAssistantMutation, DeleteAssistantMutationVariables>,
) =>
  useMutation<DeleteAssistantMutation, DeleteAssistantMutationVariables>(DELETE_ASSISTANT_MUTATION, {
    update: (cache, { data }) => {
      const deletedAssistantId = data?.deleteAssistant?.assistant?.id;
      if (deletedAssistantId == null) return;
      const assistants = cache.readQuery<ListAssistantIdsQuery>({ query: LIST_ASSISTANT_IDS_QUERY });
      const newAssistants = assistants?.assistants?.nodes.filter((t) => t.id !== deletedAssistantId);
      cache.writeQuery<ListAssistantIdsQuery>({
        query: LIST_ASSISTANT_IDS_QUERY,
        data: { assistants: { nodes: newAssistants ?? [] } },
      });
    },
    ...options,
  });

//endregion

//region Archive

const ARCHIVE_ASSISTANT_MUTATION = gql`
    mutation ArchiveAssistant($id: Int!) {
        archiveAssistant(input: { id: $id }) {
            assistant {
                id
            }
        }
    }
`;

export const useArchiveAssistantMutation = (
  options?: MutationHookOptions<ArchiveAssistantMutation, ArchiveAssistantMutationVariables>,
) =>
  useMutation<ArchiveAssistantMutation, ArchiveAssistantMutationVariables>(ARCHIVE_ASSISTANT_MUTATION, {
    update: (cache, _result, { variables }) => {
      const archivedAssistantId = variables?.id;
      if (archivedAssistantId == null) return;
      const assistants = cache.readQuery<ListAssistantIdsQuery>({ query: LIST_ASSISTANT_IDS_QUERY });
      const newAssistants = assistants?.assistants?.nodes.filter((t) => t.id !== archivedAssistantId);
      cache.writeQuery<ListAssistantIdsQuery>({
        query: LIST_ASSISTANT_IDS_QUERY,
        data: { assistants: { nodes: newAssistants ?? [] } },
      });
    },
    ...options,
  });

//endregion

//region Configure

const RECOMMEND_ASSISTANT_CONFIGURATION_MUTATION = gql`
    mutation RecommendAssistantConfiguration($assistantId: Int!) {
        recommendAssistantConfiguration(input: { assistantId: $assistantId }) {
            info
        }
    }
`;

export const useRecommendAssistantConfigurationMutation = (
  options?: MutationHookOptions<RecommendAssistantConfigurationMutation, RecommendAssistantConfigurationMutationVariables>,
) =>
  useMutation<RecommendAssistantConfigurationMutation, RecommendAssistantConfigurationMutationVariables>(
    RECOMMEND_ASSISTANT_CONFIGURATION_MUTATION,
    options,
  );

//endregion

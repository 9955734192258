import {
  createBrowserRouter, Navigate,
  RouterProvider,
} from 'react-router-dom';
import appRoutes from "./AppRoutes.tsx";
import websiteRoutes from "./WebsiteRoutes.tsx";

const router = createBrowserRouter([
  {
    path: 'app',
    children: appRoutes,
  },
  {
    path: '/',
    children: websiteRoutes,
  },
]);

const Router = () => (
    <RouterProvider router={router} />
);

export default Router;

export enum StepType {
  Conversation = 'conversation',
  MultipleChoice = 'multiple-choice',
  NPS = 'nps',
  Rating = 'rating',
  FileUpload = 'file-upload',
}


export interface BaseStep {
  type: StepType;
  title?: string;
  goal?: string;
}

export interface ConversationStep extends BaseStep {
  type: StepType.Conversation;
}

export interface MultipleChoiceStep extends BaseStep {
  type: StepType.MultipleChoice;
  options?: string[];
  ask_why?: boolean;
}

export interface NPSStep extends BaseStep {
  type: StepType.NPS;
  ask_why?: boolean;
}

export interface RatingStep extends BaseStep {
  type: StepType.Rating;
  ask_why?: boolean;
}

export interface FileUploadStep extends BaseStep {
  type: StepType.FileUpload;
}

export type Step = ConversationStep | MultipleChoiceStep | NPSStep | RatingStep | FileUploadStep;

export interface AssistantInfo {
  goal: string;

  // Identity
  name: string;
  role: string;
  avatar: string;

  // Flow
  welcomeMessage: string;
  welcomeButtonText: string;
  steps: Step[];
  endMessage?: string;
  endButtonText?: string;
  endButtonUrl?: string;

  // Context
  context: string;

  // Style
  colorPrimary?: string;
  colorBgContainer?: string;
  companyIcon?: string;
}


/**
 * Adds a `key` property to every object in the given data list, using the value of the property with the given name.
 * This is useful for rendering data where a key is needed for rendering optimisation.
 * An example is a datasource of an Ant table.
 * @param data A list of data objects to add keys to.
 * @param property The name of the property to use as key in each data object.
 */
export const addKeys = <T, K extends keyof T>(
  data: T[] | undefined,
  property: K,
): (T & { key: T[K] })[] | undefined => {
  return data?.map((element) => ({
    ...element,
    key: element[property],
  }));
};

import {ResponsiveBar, ResponsiveBarSvgProps, BarDatum} from "@nivo/bar";

export interface Datum extends BarDatum {
    score: string,
    value: number,
    color: string,
}

type BarChartProps = ResponsiveBarSvgProps<Datum>;

const BarScoreChart = (props: BarChartProps) => {
    return (
        <div style={{height: '400px'}}>
            <ResponsiveBar
                indexBy="score"
                margin={{top: 50, right: 50, bottom: 50, left: 60}}
                padding={0.3}
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                colors={{datum: 'data.color'}}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'NPS Score',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                animate={true}
                {...props}
            />
        </div>
    );
};

export default BarScoreChart

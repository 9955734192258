import {useCallback, useState} from "react";
import type {Message} from 'types';
import type {Assistant, Chat} from "../../gql/graphql.ts";
import type {CompleteChatMutationTuple} from "../../api/chat.api.ts";
import { useFinishChatMutation} from "../../api/chat.api.ts";
import ChatUIGeneral from "./ChatUIGeneral.tsx";

const USE_OPENAI = true;

interface ChatUIProps {
  assistant: Pick<Assistant, 'identifier' | 'info'>;
  chat: Pick<Chat, 'id' | 'messages'>;
  useCompleteChat: () => CompleteChatMutationTuple;
  onSubmit: () => void;
}

function ChatUI({assistant, chat, useCompleteChat, onSubmit}: ChatUIProps) {
  const [messages, setMessages] = useState<Message[]>(chat.messages ?? []);

  const [completeChat, completeChatMutation] = useCompleteChat();
  const [finishChat, finishChatMutation] = useFinishChatMutation();

  const sendMessage = useCallback(async (message: string) => {
    if (message === '') return;
    if (USE_OPENAI) {
      setMessages([...messages, {
        role: 'user',
        content: message,
      }]);
      completeChat({
        variables: {
          id: chat.id,
          assistantIdentifier: assistant.identifier,
          userMessage: message,
        },
        onCompleted: (data) => {
          const newMessages = data?.chat?.messages;
          if (newMessages) setMessages(newMessages);
        },
      })
    } else {
      setMessages((prevMessages) => [...prevMessages, {
        role: 'user',
        content: message,
      }, {
        role: 'assistant',
        content: 'I am sorry to hear that. What can we do to improve our product?',
      }]);
    }
  }, [messages, setMessages]);

  return <ChatUIGeneral
      isAssistantMessageLoading={completeChatMutation.loading}
      isFinishChatLoading={finishChatMutation.loading}
      messages={messages}
      name={assistant.info?.name}
      onFinishCallback={() => finishChat({
        variables: {
          id: chat.id,
          assistantIdentifier: assistant.identifier,
        },
        onCompleted: () => {
          onSubmit();
        },
      })}
      onSendMessageCallback={sendMessage}
      />
}

export default ChatUI;

import type {ChangeEvent, HTMLProps} from "react";
import {PlusOutlined, RobotOutlined} from "@ant-design/icons";
import classNames from "classnames";

interface AvatarProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
  avatar?: string;
  icon?: React.ReactNode;
  onChange?: (avatar: string) => void;
  maxSize?: number;
}

export default function Avatar({ avatar, icon, onChange, maxSize = 160, disabled, className, ...otherProps }: AvatarProps) {
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (!event.target?.result) return;
      const img = new Image();
      img.onload = () => {
        const minDimension = Math.min(img.width, img.height);
        const scale = maxSize / minDimension;

        const canvas = document.createElement('canvas');
        canvas.width = maxSize;
        canvas.height = maxSize;

        const ctx = canvas.getContext('2d');
        const offsetX = (maxSize - img.width * scale) / 2;
        const offsetY = (maxSize - img.height * scale) / 2;
        ctx.drawImage(img, 0, 0, img.width, img.height, offsetX, offsetY, img.width * scale, img.height * scale);

        const base64String = canvas.toDataURL('image/png');
        onChange?.(base64String);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      className={classNames(
        "relative h-[80px] w-[80px] rounded-full overflow-clip bg-[#fafafa]",
        "hover:text-primary [&:hover>.upload-button]:opacity-100 [&:hover>.upload-button]:border-primary",
        className,
      )}
      {...otherProps}
    >
      {onChange && !disabled ? <div
        className={classNames(
          "upload-button absolute inset-0 flex flex-col gap-1 items-center justify-center rounded-full",
          "border border-dashed border-[#d9d9d9] transition-colors",
            )}
        >
          <PlusOutlined />
          Upload
        </div> : null}
      {avatar && (
        <>
          <img
            alt="Avatar"
            className="relative w-full h-full object-cover z-10"
            src={avatar}
          />
          {onChange && !disabled ? <div
              className="upload-button absolute inset-0 flex flex-col gap-1 items-center justify-center text-white bg-black bg-opacity-70 rounded-full opacity-0 transition-opacity z-20 cursor-pointer select-none"
            >
              <PlusOutlined />
              Upload
            </div> : null}
        </>
      )}
      {!avatar && (!onChange || disabled) && (
        <div className="relative w-full h-full flex items-center justify-center text-[32px] text-[#d9d9d9]">
          {icon ?? <RobotOutlined />}
        </div>
      )}
      {onChange && !disabled ? <input
          accept="image/*"
          className="absolute inset-0 opacity-0 cursor-pointer z-30"
          onChange={handleImageChange}
          type="file"
        /> : null}
    </div>
  );
};

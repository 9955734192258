import {PropsWithChildren, useEffect, useState} from 'react';
import {Authenticator as AWSAuthenticator, Theme, ThemeProvider, useAuthenticator} from '@aws-amplify/ui-react';
import {GA_EVENT, GoogleAnalytics} from "../../config/googleAnalytics.ts";
import '@aws-amplify/ui-react/styles.css';

import './Authenticator.css';

function GAUserIdUpdater() {
  const { user } = useAuthenticator((context) => [context.user]);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Bad types: user can be undefined
  const userId = user?.username;
  useEffect(() => {
    GoogleAnalytics.set({ userId });
  }, [userId]);
  return null;
}

function GAAuthEventUpdater() {
  const { route } = useAuthenticator((context) => [context.route]);
  const [method, setMethod] = useState<"signIn" | "signUp" | null>(null);

  // Keep track of whether we are signing in or signing up
  useEffect(() => {
    if (route === 'signIn') {
      setMethod('signIn');
    } else if (route === 'signUp') {
      setMethod('signUp');
    }
  }, [route]);

  // Send the event to Google Analytics as soon as we become authenticated
  useEffect(() => {
    if (route !== 'authenticated' || !method) return;
    if (method === 'signIn') {
      GoogleAnalytics.event(GA_EVENT.LOGIN);
    } else {
      GoogleAnalytics.event(GA_EVENT.SIGN_UP);
    }
  }, [route, method]);

  return null;
}

const Authenticator = ({ children }: PropsWithChildren) => {
  const theme: Theme = {
    name: 'Querri Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: '#F9FAFC',
          },
        },
        brand: {
          primary: {
            '10': '#1677FF',
            '20': '#1677FF',
            '40': '#1677FF',
            '60': '#1677FF',
            '80': '#1677FF', // Default
            '90': '#3F97FF',
            '100': '#1677FF',
          },
        },
      },
      components: {
        authenticator: {
          router: {
            borderWidth: {
              value: '0px',
            },
          },
        },
        button: {
          link: {
            _hover: {
              backgroundColor: {
                value: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return (
    <AWSAuthenticator.Provider>
      <GAUserIdUpdater/>
      <GAAuthEventUpdater/>
      <ThemeProvider theme={theme}>
        <AWSAuthenticator
          loginMechanisms={['email']}
          signUpAttributes={['name']}
          formFields={{
            signUp: {
              name: {
                order: 1,
              },
            },
          }}
        >
          {children}
        </AWSAuthenticator>
      </ThemeProvider>
    </AWSAuthenticator.Provider>
  );
};

export default Authenticator;

import {Button, Input} from "antd";
import {BuildOutlined} from "@ant-design/icons";
import classNames from "classnames";
import type {Assistant} from "../../../../gql/graphql.ts";
import type {AssistantInfo} from "../assistantTypes.ts";
import {useRecommendAssistantConfigurationMutation} from "../../../../api/assistant.api.ts";

interface ConfigureGoalProps {
  assistant: Pick<Assistant, 'id' | 'info'>;
  updateAssistantInfo: (info: Partial<AssistantInfo>) => void;
  editable: boolean;
}

export default function ConfigureGoal({ assistant, updateAssistantInfo, editable }: ConfigureGoalProps) {
  const [recommendAssistantConfiguration, recommendAssistantConfigurationResult] = useRecommendAssistantConfigurationMutation({
    onCompleted: (data) => {
      updateAssistantInfo(data.recommendAssistantConfiguration?.info ?? {});
    },
  });

  return (
    <>
      <Input.TextArea
        autoSize={{ minRows: 1 }}
        className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
        onChange={(event) => { updateAssistantInfo({ goal: event.target.value }); }}
        placeholder="Goal (e.g. Ask people about their experience with the product and what they would like to see in the future.)"
        value={assistant.info?.goal}
        variant="borderless"
        disabled={!editable}
      />
      {editable && (<Button
        className="mt-4 ml-[11px]"
        icon={<BuildOutlined />}
        loading={recommendAssistantConfigurationResult.loading}
        onClick={() => recommendAssistantConfiguration({
          variables: {
            assistantId: assistant.id,
          },
        })}
        type="primary"
      >
        Generate Assistant
      </Button>)}
    </>
  );
};

import {PropsWithChildren} from 'react';

import './MultilineText.css';

const MultilineText = ({ children }: PropsWithChildren) => (
  <span className="multiline-text">
    {children}
  </span>
);

export default MultilineText;

import type { ReactNode } from "react";
import classNames from "classnames";

interface Feature {
  name: string;
  description?: string;
  icon: React.ComponentType<{ className: string | undefined }>;
}

interface FeatureSectionProps {
  id?: string;
  left?: true;
  highlight: ReactNode;
  title: ReactNode;
  description: ReactNode;
  features?: Feature[];
  image?: Pick<React.HTMLProps<"img">, "src" | "alt" | "width" | "height">;
  content?: ReactNode;
}

export default function FeatureSection({ id, left, highlight, title, description, features, image, content }: FeatureSectionProps) {
  return (
    <div id={id} className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className={classNames(["lg:pt-4", left ? "order-2" : ""])}>
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-primary">{highlight}</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">{description}</p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features?.map((feature) => (
                  <div className="relative pl-9" key={feature.name}>
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-primary" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="ms-0">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex flex-col">
            {image ? <img
              {...image}
              className={classNames([
                "w-full h-auto rounded-xl",
                left ? "self-end md:-mr-4 lg:-mr-0" : "md:-ml-4 lg:-ml-0",
              ])}
            /> : null}
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}

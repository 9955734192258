import {ConfigProvider} from 'antd';
import {gql, useQuery} from "@apollo/client";
import {useParam} from '../../util/useParam.ts';
import Spinner from '../../components/Spinner/Spinner.tsx';
import QuerriIcon from '../../components/QuerriIcon/QuerriIcon.tsx';
import type {AssistantByIdentifierQuery, AssistantByIdentifierQueryVariables} from "../../gql/graphql.ts";
import {useCompleteChatMutation, useStartChat} from "../../api/chat.api.ts";
import type {AssistantInfo} from "../assistants/assistant/assistantTypes.ts";
import ChatLoaded from "./ChatLoaded.tsx";
import './ChatLoaderWrapper.css'

const useAssistantByIdentifier = (identifier: string) => {
    const {data, ...query} = useQuery<AssistantByIdentifierQuery, AssistantByIdentifierQueryVariables>(gql`
        query AssistantByIdentifier($identifier: UUID!) {
            assistantByIdentifier(identifier: $identifier) {
                identifier
                info
            }
        }
    `, {
        variables: {
            identifier,
        },
    });
    return {
        assistant: data?.assistantByIdentifier,
        ...query,
    };
};


function ChatLoaderWrapper() {
    const assistantIdentifier = useParam('assistantIdentifier') ?? '';
    const {assistant, ...assistantQuery} = useAssistantByIdentifier(assistantIdentifier);

    if (assistantQuery.loading || !assistant) return (
        <div className="chat-loader-wrapper">
            <div className="flex-1 flex flex-col gap-4 items-center justify-center">
                <QuerriIcon/>
                <Spinner size={30}/>
                Fetching your assistant...
            </div>
        </div>
    );

    const assistantInfo = assistant.info as AssistantInfo;

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: assistantInfo.colorPrimary ?? "#1677ff",
                },
            }}
        >
            <div
              className="chat-loader-wrapper"
              style={{
                  backgroundColor: assistantInfo.colorBgContainer ?? "#F8FAFB",
              }}
            >
                <ChatLoaded
                  assistant={assistant}
                  useCompleteChat={useCompleteChatMutation}
                  useStartChat={useStartChat}
                />
            </div>
        </ConfigProvider>
    )
}

export default ChatLoaderWrapper

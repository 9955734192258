import {Button, Modal} from "antd";

interface Props {
  open: boolean;
  onClose: () => void;
}

const HelpModal = ({open, onClose}: Props) => {
  return (
    <Modal
      title="Help"
      open={open}
      centered
      footer={<Button onClick={onClose}>Close</Button>}
    >
      The AI assistant has been asked to perform an interview with you.
      Think of it as an automated survey.
      It'll ask you a few questions, and you can answer them by typing a response in the chat.
      <br/>
      The contents of the chat will only be saved and processed if you click Submit.
    </Modal>
  )
};

export default HelpModal;

import { Input } from "antd";
import {Assistant} from "../../../../gql/graphql.ts";
import {AssistantInfo} from "../assistantTypes.ts";
import classNames from "classnames";

interface ConfigureGoalProps {
  assistant: Pick<Assistant, 'info'>;
  updateAssistantInfo: (info: Partial<AssistantInfo>) => void;
  editable: boolean;
}

export default function ConfigureContext({ assistant, updateAssistantInfo, editable }: ConfigureGoalProps) {
  return (
    <Input.TextArea
      className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
      bordered={false}
      placeholder="Any context you want to share with your assistant? (e.g. Product information, pricing, team details, etc.)"
      autoSize={{ minRows: 1 }}
      value={assistant.info?.context}
      onChange={(event) => updateAssistantInfo({ context: event.target.value })}
      disabled={!editable}
    />
  );
};

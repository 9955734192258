import {Card, CardProps, Dropdown, Modal, Statistic, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, EllipsisOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import AssistantStatusTag from "./AssistantStatusTag.tsx";
import {Assistant, AssistantStatus} from "../../gql/graphql.ts";
import {useNavigate} from "react-router-dom";
import {useArchiveAssistantMutation, useDeleteAssistantMutation} from "../../api/assistant.api.ts";
import Avatar from "../Avatar/Avatar.tsx";

interface AssistantCardProps extends CardProps {
  assistant: Pick<Assistant, "id" | "status" | "info"> & {
    chats: {
      totalCount: number;
    };
  };
}

export default function AssistantCard({ assistant, ...cardProps }: AssistantCardProps) {
  const navigate = useNavigate();

  const [removeAssistant, removeAssistantMutation] = useDeleteAssistantMutation();
  const [archiveAssistant, archiveAssistantMutation] = useArchiveAssistantMutation();

  const deleteEnabled = assistant.status === AssistantStatus.Draft;

  return (
    <Card
      className="overflow-clip"
      hoverable
      bordered={false}
      cover={
        <div className="bg-primary p-4 pt-8" style={{backgroundColor: assistant.info?.colorPrimary}}>
          <Avatar
            className="m-auto"
            avatar={assistant.info?.avatar}
          />
        </div>
      }
      actions={[
        <EditOutlined
          key="edit"
          onClick={(event) => {
            event.stopPropagation();
            navigate(`${assistant.id}#configure`)
          }}
        />,
        <Dropdown
          key="more"
          menu={{
            items: [
              {
                key: 'delete',
                icon: <DeleteOutlined />,
                label: (
                  <Tooltip
                    title={deleteEnabled ? "" : "You can only delete draft assistants."}
                  >
                    Delete assistant
                  </Tooltip>
                ),
                danger: true,
                disabled: !deleteEnabled,
                onClick: ({ domEvent }) => {
                  domEvent.stopPropagation();
                  Modal.confirm({
                    title: 'Are you sure?',
                    okText: 'Yes',
                    cancelText: 'No',
                    okButtonProps: {
                      loading: removeAssistantMutation.loading,
                    },
                    content: `Are you sure you want to delete assistant ${assistant.info?.name ?? 'No name'}? Any existing chats will be lost.`,
                    onOk: () => removeAssistant({
                      variables: {
                        id: assistant.id,
                      },
                    }),
                  });
                },
              },
              {
                key: 'archive',
                icon: <EyeInvisibleOutlined />,
                label: 'Archive assistant',
                onClick: ({ domEvent }) => {
                  domEvent.stopPropagation();
                  Modal.confirm({
                    title: 'Are you sure?',
                    okText: 'Yes',
                    cancelText: 'No',
                    okButtonProps: {
                      loading: archiveAssistantMutation.loading,
                    },
                    content: `Are you sure you want to archive assistant ${assistant.info?.name ?? 'No name'}?`,
                    onOk: () => archiveAssistant({
                      variables: {
                        id: assistant.id,
                      },
                    }),
                  });
                },
              },
            ],
          }}
          trigger={['click']}
          arrow={{ pointAtCenter: true }}
          placement="bottom"
        >
          <EllipsisOutlined
            key="ellipsis"
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        </Dropdown>,
      ]}
      {...cardProps}
    >
      <AssistantStatusTag
        className="absolute top-0 right-0 mt-2 mr-2"
        status={assistant.status}
      />
      <div className="flex items-center gap-4 overflow-clip">
        <Card.Meta
          className="flex-1 overflow-clip"
          title={assistant?.info?.name ?? <span style={{color: 'grey'}}>No name</span>}
          description={
            <div className="overflow-clip whitespace-nowrap overflow-ellipsis">{assistant?.info?.role ?? "No role"}</div>
          }
        />
        <Statistic
          title="Chats"
          value={assistant.chats.totalCount}
        />
      </div>
    </Card>
  )
}

import {Button, Card} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import MessageList from "../chat/MessageList.tsx";
import {Chat} from "../../gql/graphql.ts";
import classNames from "classnames";

interface Props {
    selectedChat: Pick<Chat, "messages" | "summary" | "createdAt">
    unSelectCallBack?: () => void;
    onClickCallback?: () => void;
    className?: string;
}

const ChatMessagesCard = ({selectedChat, unSelectCallBack, onClickCallback, className }: Props) => {
    return <Card
        className={classNames('messages-container', className)}
        title={`Messages - ${new Date(selectedChat.createdAt).toLocaleString()}`}
        extra={ unSelectCallBack ? <Button
            type='text'
            icon={<CloseOutlined/>}
            onClick={() => unSelectCallBack ? unSelectCallBack() : {}}
        /> : null}
        onClick={() => onClickCallback ? onClickCallback() : {}}
    >
        <MessageList
            messages={selectedChat.messages}
            typing={false}
        />
    </Card>
}

export default ChatMessagesCard;

import {Empty, Segmented, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import Page from '../../layout/Page/Page.tsx';
import Header from '../../layout/Header/Header.tsx';
import {useNavigate} from 'react-router-dom';
import {addKeys} from '../../util/addKeys.ts';
import AssistantStatusTag from '../../components/assistant/AssistantStatusTag.tsx';
import MultilineText from '../../components/MultilineText/MultilineText.tsx';
import {gql, useQuery} from "@apollo/client";
import {Assistant, ListAssistantsQuery, ListAssistantsQueryVariables} from "../../gql/graphql.ts";
import CardGrid from "../../components/CardGrid/CardGrid.tsx";
import AssistantCard from "../../components/assistant/AssistantCard.tsx";
import SearchInput from "../../components/SearchInput/SearchInput.tsx";
import {useMemo, useState} from "react";
import {AppstoreOutlined, TableOutlined} from "@ant-design/icons";
import CreateAssistantButton from "../../components/assistant/CreateAssistantButton/CreateAssistantButton.tsx";

enum View {
  CARDS = 'cards',
  TABLE = 'table',
}

const useAssistants = () => {
    const { data, ...query } = useQuery<ListAssistantsQuery, ListAssistantsQueryVariables>(gql`
        query ListAssistants {
            assistants {
                nodes {
                    id
                    status
                    info
                    chats {
                        totalCount
                    }
                }
            }
        }
    `);
  return {
    assistants: data?.assistants?.nodes,
    ...query,
  };
};

const Assistants = () => {
  const navigate = useNavigate();
  const assistantsQuery = useAssistants();

  const [search, setSearch] = useState('');
  const [view, setView] = useState<View>(View.CARDS);

  const assistants = useMemo(() => {
    if (!search) return assistantsQuery.assistants ?? [];
    return assistantsQuery.assistants?.filter((assistant) => {
      const name = assistant?.info?.name?.toLowerCase() ?? '';
      const role = assistant?.info?.role?.toLowerCase() ?? '';
      return name.includes(search.toLowerCase()) || role.includes(search.toLowerCase());
    }) ?? [];
  }, [assistantsQuery.assistants, search]);

  const columns: ColumnsType<Assistant> = [
    {
      title: 'Name',
      key: 'name',
      render: (assistant: Assistant) => assistant?.info?.name ?? <span style={{color: 'grey'}}>No name</span>
    },
    {
      title: 'Role',
      key: 'role',
      render: (assistant: Assistant) => assistant?.info?.role ?? <span style={{color: 'grey'}}>No role</span>
    },
    {
      title: 'Goal',
      key: 'goal',
      render: (assistant: Assistant) => <MultilineText>{assistant?.info?.goal ?? ''}</MultilineText>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status: Assistant['status']) => <AssistantStatusTag status={status} />,
    },
  ];

  const empty = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span>
          No assistants found
        </span>
      }
    >
      <CreateAssistantButton />
    </Empty>
  )

  return (
    <Page
      header={<Header
        breadcrumb={[
          {
            title: 'Assistants',
          },
        ]}
        actions={<CreateAssistantButton />}
      />}
    >
      <Page.Wrapper>
        <div className="mb-4 flex justify-between">
          <SearchInput
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <Segmented
            options={[
              { label: 'Cards', value: View.CARDS, icon: <AppstoreOutlined /> },
              { label: 'Table', value: View.TABLE, icon: <TableOutlined /> },
            ]}
            onChange={(value) => setView(value as View)}
          />
        </div>
        <div className="overflow-scroll p-[15px] m-[-15px]">
          {view === View.CARDS ? (
            <CardGrid
              loading={assistantsQuery.loading}
              empty={empty}
            >
              {assistants.map((assistant) => (
                <AssistantCard assistant={assistant} key={assistant.id} onClick={() => navigate(`${assistant.id}`)} />
              ))}
            </CardGrid>
          ) : (
            <Table
              bordered
              columns={columns}
              dataSource={addKeys(assistants, 'id')}
              onRow={(assistant) => ({
                onClick: () => navigate(`${assistant.id}`),
              })}
              loading={assistantsQuery.loading}
              locale={{
                emptyText: empty,
              }}
              pagination={false}
            />
          )}
        </div>
      </Page.Wrapper>
    </Page>
  );
};

export default Assistants;

import {
  Navigate,
  Outlet,
  RouteObject,
} from 'react-router-dom';
import Authenticator from '../components/Authenticator/Authenticator.tsx';
import ChatLoadedWrapper from '../pages/chat/ChatLoaderWrapper.tsx';
import Assistants from '../pages/assistants/Assistants.tsx';
import Assistant from '../pages/assistants/assistant/Assistant.tsx';

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to='assistants' />,
  },
  {
    // Authenticated routes
    element: <Authenticator><Outlet/></Authenticator>,
    children: [
      {
        path: 'assistants',
        children: [
          {
            index: true,
            element: <Assistants/>,
          },
          {
            path: ':assistantId',
            element: <Assistant/>,
          },
        ],
      },
    ],
  },
  {
    path: 'chat/:assistantIdentifier',
    element: <ChatLoadedWrapper/>,
  },
  {
    path: '*',
    element: <Navigate replace to='assistants' />,
  },
];

export default routes;

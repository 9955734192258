import React from 'react'
import ReactDOM from 'react-dom/client'
import {configureAmplify} from './config/amplify.ts';
import {configureGoogleAnalytics} from "./config/googleAnalytics.ts";
import {configureHotjar} from "./config/hotjar.ts";
import {configureSentry} from "./config/sentry.ts";
import Router from './routes/Router.tsx';
import APIProvider from './api/APIProvider.tsx';

import './index.css'

configureAmplify();
configureGoogleAnalytics();
configureHotjar();
configureSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <APIProvider>
      <Router/>
    </APIProvider>
  </React.StrictMode>,
)

import {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, Card, Empty, List, Progress, Segmented} from 'antd';
import {gql, useQuery} from "@apollo/client";
import {
    Assistant,
    AssistantStatus,
    ChatsForAssistantQuery,
    ChatsForAssistantQueryVariables, ChatType
} from "../../../../gql/graphql.ts";
import PieChart from "./HelperCharts/PieChart.tsx";

import {useCompleteMessagesMutation} from "../../../../api/chat.api.ts";
import ChatUIGeneral from "../../../chat/ChatUIGeneral.tsx";
import type {Message} from "types";
import {
    ChatCompletionAssistantMessageParam,
    ChatCompletionSystemMessageParam,
    ChatCompletionUserMessageParam
} from "openai/resources/chat/completions";
import Chart from "./Chart.tsx";
import {Result} from "../resultTypes.ts";
import {BarChartOutlined, WechatOutlined} from "@ant-design/icons";
import AssistantResultChats from "./AssistantResultChats.tsx";
import {GA_EVENT, GoogleAnalytics} from "../../../../config/googleAnalytics.ts";

import './AssistantResults.css';

const USE_OPENAI = true;

enum View {
    ANALYTICS = 'ANALYTICS',
    CHATS = 'CHATS',
}


const useChatsForAssistant = (id: number) => {
    const {data, ...query} = useQuery<ChatsForAssistantQuery, ChatsForAssistantQueryVariables>(gql`
        query ChatsForAssistant($id: Int!) {
            chats(condition: {assistantId: $id}) {
                nodes {
                    id
                    createdAt
                    type
                    status
                    messages
                    summary
                    results
                    sentiment
                    keyTopics
                }
            }
        }
    `, {
        variables: {
            id,
        },
    });
    return {
        chats: data?.chats?.nodes,
        ...query,
    };
};

interface AssistantResultsProps {
    assistant: Pick<Assistant, 'id' | 'info' | 'status'>;
}

type Sentiment = "happy" | "neutral" | "unhappy";

function AssistantResults({assistant}: AssistantResultsProps) {
    const [view, setView] = useState<View>(View.ANALYTICS);
    
    const chatsQuery = useChatsForAssistant(assistant.id);

    // Check if we should log a GoogleAnalytics event
    const hasNonPreviewChats = chatsQuery.chats?.some(c => c.type !== ChatType.Preview) ?? false;
    useEffect(() => {
        if (!hasNonPreviewChats) return;
        GoogleAnalytics.event(GA_EVENT.ANALYSE_RESULTS);
    }, [hasNonPreviewChats]);
    
    const chatsToAnalyse = JSON.stringify((chatsQuery.chats ?? [])
      .map(c => (c.messages as Message[])
        .filter(m => m.role !== "system")
        .map(m =>
            ({
                role: m.role,
                content: m.content
            })
        )
    ))
    
    const systemMessage: ChatCompletionSystemMessageParam = useMemo(() => ({
        content: `You are a chatbot that is used on the analysis side. You analyse conversations that were done with an AI assistant and help find the user the relevant information. You answer in a very short and clear way. Just giving back the results that were asked. Also anonymize the conversations. Do not ever give names
      The conversations that you need to analyse are the following: ${chatsToAnalyse}`,
        role: "system"
    }), [chatsToAnalyse]);
    
    const firstMessage: ChatCompletionAssistantMessageParam = useMemo(() => ({
        content: `Hi, I'm here to help you analyse the results. How can I help you?`,
        role: "assistant"
    }), []);
    
    const [messages, setMessages] = useState<Message[]>([]);
    const [completeMessages, completeMessagesMutation] = useCompleteMessagesMutation();
    
    useEffect(() => {
        setMessages([systemMessage, firstMessage])
    }, [systemMessage, firstMessage]);

    const sendMessage = useCallback(async (message: string) => {
            if (message === '') return;
            const newMessage: ChatCompletionUserMessageParam = {
                role: 'user',
                content: message,
            }
            if (USE_OPENAI) {
                const messagesToSend = [...messages, newMessage]
                setMessages(messagesToSend);
                await completeMessages({
                    variables: {
                        messages: JSON.stringify(messagesToSend)
                    },
                    onCompleted: (data) => {
                        const newMessages = data?.completeMessages?.messages as string | undefined;
                        if (newMessages) setMessages(JSON.parse(newMessages) as Message[]);
                    },
                })
            } else {
                setMessages((prevMessages) => [...prevMessages, newMessage,
                    {
                        role: 'assistant',
                        content: 'I am sorry to hear that. What can we do to improve our product?',
                    }]);
            }
        }
        ,
        [messages, setMessages]
    );
    
    const [selectedIndexCard, setSelectedIndexCard] = useState(0);
    
    const resultsForChatIndex = (index: number) => chatsQuery.chats?.flatMap(c => (c.results as Result[]).filter(r => r.step_index === index))
    
    const sentimentRecord: Record<Sentiment, { value: number, color: string }> = {
        happy: {
            value: 0,
            "color": "#1DBA5D"
        },
        neutral: {
            value: 0,
            "color": "#FCAC04"
        },
        unhappy: {
            value: 0,
            "color": "#FC586E"
            
        }
    }
    
    chatsQuery.chats?.forEach(c => {
        if (!c.sentiment) return;
        sentimentRecord[c.sentiment.toLowerCase() as Sentiment].value += 1;
    })
    
    const sentimentResults = ((Object.keys(sentimentRecord ?? {}) as unknown as Sentiment[]).map(k => ({
        id: k.toString(),
        label: k.toString(),
        value: sentimentRecord[k].value,
        color: sentimentRecord[k].color
    }))).filter(s => s.value !== 0);
    
    const topicsSet: Record<string, number> = {}
    
    chatsQuery.chats?.forEach(c => {
        let keyTopics;
        try {
            keyTopics = JSON.parse(c.keyTopics);
        } catch (e) {
            return;
        }
        if (keyTopics == null) {
            return;
        }
        keyTopics.forEach(kT => {
            if (topicsSet[kT]) {
                topicsSet[kT]++;
            } else {
                topicsSet[kT] = 1;
            }
        });
    })
    
    const topics = Object.keys(topicsSet).map(k => ({
        name: k.toString(),
        value: topicsSet[k]
    })) as { name: string, value: number }[]
    
    const largestTopic = topics.length ? topics.reduce((prev, current) => (prev.value > current.value) ? prev : current) :
        {name: 'default', value: 1};
    
    return (
        <>
            <div className="flex mb-4 justify-end">
                <Segmented
                    options={[
                        {label: 'Analytics', value: View.ANALYTICS, icon: <BarChartOutlined/>},
                        {label: 'Chats', value: View.CHATS, icon: <WechatOutlined/>},
                    ]}
                    onChange={(value) => setView(value as View)}
                />
            </div>
            {assistant.status !== AssistantStatus.Active && (<Alert
                className="mb-4"
                showIcon
                message="Your assistant is not active yet, so you're looking at the results from your preview chats. They will be removed when you activate your assistant."
            />)}
            {view === View.ANALYTICS && <div className="flex gap-4 h-full overflow-hidden">
                <Card
                    className="flex-1"
                >
                    <div className="flex flex-col gap-4 overflow-scroll">
                        <h2>General</h2>
                        <div className="flex flex-1 gap-4">
                            <Card
                                title="Topics"
                                className="flex-1"
                            >
                                <div className="h-[400px] overflow-scroll">
                                    <List
                                        dataSource={topics}
                                        size="small"
                                        renderItem={item => (
                                            <List.Item>
                                                <div>{item.name}</div>
                                                <Progress
                                                    className="w-1/2"
                                                    percent={Math.round((item.value / largestTopic.value) * 100)}
                                                    status="normal"
                                                    format={() => item.value.toString()}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </Card>
                            <Card
                                title="Sentiment"
                                className="flex-1"
                            >
                                {sentimentResults.length !== 0 ? <PieChart data={sentimentResults}/> : <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />}
                            </Card>
                        </div>
                        <h2>Questions</h2>
                        {assistant.info.steps ? <div className="flex w-full gap-4 h-[500px]">
                            <div className="flex flex-col gap-4 w-1/3 h-full overflow-scroll">
                                {assistant.info.steps.map((item, index) => {
                                    return <Card
                                        title={item.title}
                                        onClick={() => setSelectedIndexCard(index)}
                                        className="overflow-visible"
                                        style={{backgroundColor: selectedIndexCard === index ? '#E7F4FF' : undefined}}
                                    >
                                        {item.goal}
                                    </Card>;
                                    
                                })}
                            </div>
                            <div className="w-2/3 h-[500px]">
                                <Chart step={assistant.info.steps[selectedIndexCard]}
                                       results={resultsForChatIndex(selectedIndexCard)}/>
                            </div>
                        </div> : <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />}
                    </div>
                </Card>
                <Card
                    className="w-[400px]"
                >
                    {!chatsQuery.loading && <ChatUIGeneral name={assistant.info.name}
                                                           messages={messages}
                                                           isAssistantMessageLoading={completeMessagesMutation.loading}
                                                           onSendMessageCallback={sendMessage}
                                                           isFinishChatLoading={false}
                                                           onFinishCallback={undefined}
                    />}
                </Card>
            </div>}
            {view === View.CHATS && chatsQuery.chats && <AssistantResultChats chats={chatsQuery.chats}/>}
        </>
    
    );
}

export default AssistantResults;

import type {ReactElement, ReactNode} from "react";
import type {MenuProps} from "antd";
import {Button, Collapse, Dropdown, Input, Switch, Tag, Tooltip} from "antd";
import {
  AimOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  BarsOutlined,
  DeleteOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  MoreOutlined,
  PlusOutlined,
  SnippetsOutlined,
  StarOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import type {Assistant} from "../../../../gql/graphql.ts";
import type {AssistantInfo, Step} from "../assistantTypes.ts";
import { StepType} from "../assistantTypes.ts";
import Label from "../../../../components/typography/Label.tsx";

interface ConfigureFlowProps {
  assistant: Pick<Assistant, 'info'>;
  updateAssistantInfo: (info: Partial<AssistantInfo>) => void;
  editable: boolean;
}

const newStepMenuItems: Exclude<MenuProps['items'], undefined> = [
  {
    label: 'Conversation',
    key: StepType.Conversation,
    icon: <MessageOutlined />
  },
  {
    label: 'Multiple Choice',
    key: StepType.MultipleChoice,
    icon: <BarsOutlined />
  },
  {
    label: 'NPS',
    key: StepType.NPS,
    icon: <AimOutlined />
  },
  {
    label: 'Rating',
    key: StepType.Rating,
    icon: <StarOutlined />
  },
  {
    label: (
      <>
        File Upload
        <Tag bordered={false} className="ml-2" color="blue">
          Coming Soon
        </Tag>
      </>
    ),
    key: StepType.FileUpload,
    icon: <SnippetsOutlined />,
    disabled: true,
  },
];

const stepTypeDefaultValues: Record<StepType, Partial<Step>> = {
  [StepType.Conversation]: {},
  [StepType.MultipleChoice]: {
    ask_why: true,
  },
  [StepType.NPS]: {
    title: 'NPS',
    goal: 'Check how likely the user is to recommend us to a friend.',
    ask_why: true,
  },
  [StepType.Rating]: {
    title: 'Rating',
    ask_why: true,
  },
  [StepType.FileUpload]: {},
};

function StepTypeIcon({ type }: { type: StepType }): ReactNode {
  const menuItem = newStepMenuItems.find((item) => item?.key === type);
  if (!menuItem || !("icon" in menuItem) || !menuItem.icon) return null;
  return menuItem.icon;
}

function StepTypeLabel({ type }: { type: StepType }): ReactNode {
  const menuItem = newStepMenuItems.find((item) => item?.key === type);
  if (!menuItem || !("label" in menuItem) || !menuItem.label) return '';
  return menuItem.label;
}

interface StepTypeButtonProps {
  step: Step;
  updateStep: (step: Step) => void;
  disabled: boolean;
}

function StepTypeButton({ step, updateStep, disabled }: StepTypeButtonProps): ReactElement {
  return (
    <Dropdown
      arrow={{
        pointAtCenter: true,
      }}
      disabled={disabled}
      menu={{
        items: [
          {
            type: 'group',
            label: 'Change step type',
          },
          ...newStepMenuItems,
        ],
        selectedKeys: [step.type],
        onClick: ({ domEvent, key }) => {
          domEvent.stopPropagation();
          const stepType = key as StepType;
          const defaultValues = stepTypeDefaultValues[stepType];
          updateStep({ ...defaultValues, ...step, type: stepType });
        },
      }}
      trigger={['click']}
    >
      <Tooltip title={<StepTypeLabel type={step.type} />}>
        <Button
          className="my-[-5px] !text-[rgba(0,0,0,0.88)]"
          disabled={disabled}
          icon={<StepTypeIcon type={step.type} />}
          onClick={(event) => { event.stopPropagation(); }}
          type="text"
        />
      </Tooltip>
    </Dropdown>
  );
}

interface StepContentProps {
  step: Step;
  updateStep: (step: Step) => void;
  editable: boolean;
}

function StepContent({ step, updateStep, editable }: StepContentProps): ReactElement {
  switch (step.type) {
    case StepType.Conversation:
      return (
        <Input.TextArea
          autoSize={{ minRows: 1 }}
          className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
          disabled={!editable}
          onChange={(event) => { updateStep({ ...step, goal: event.target.value }); }}
          placeholder="What's the goal of the conversation? (e.g. Ask people about their experience with the product and what they would like to see in the future.)"
          value={step.goal}
          variant="borderless"
        />
      );
    case StepType.MultipleChoice:
      return (
        <>
          <Input.TextArea
            autoSize={{ minRows: 1 }}
            className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
            disabled={!editable}
            onChange={(event) => { updateStep({ ...step, goal: event.target.value }); }}
            placeholder="What's the goal? (e.g. Find out what the user's favorite feature is.)"
            value={step.goal}
            variant="borderless"
          />
          <Label className="mt-4">Options</Label>
          {step.options?.map((option, index) => (
            <div
              className="flex items-center [&:hover>.delete-option-button]:opacity-100"
              key={option}
            >
              <Input
                className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
                disabled={!editable}
                onChange={(event) => { updateStep({
                  ...step,
                  options: step.options?.map((o, i) => i === index ? event.target.value : o),
                }); }}
                placeholder="Option"
                value={option}
                variant="borderless"
              />
              {editable ? <Button
                className="delete-option-button opacity-0 transition-opacity duration-200"
                icon={<DeleteOutlined />}
                onClick={() => { updateStep({
                  ...step,
                  options: step.options?.filter((_, i) => i !== index),
                }); }}
                type="text"
              /> : null}
            </div>
          ))}
          {editable ? <Button
            className="mt-2"
            icon={<PlusOutlined />}
            onClick={() => { updateStep({
              ...step,
              options: [
                ...(step.options ?? []),
                '',
              ],
            }); }}
            type="dashed"
          >
            Add Option
          </Button> : null}
          <Label className="mt-4">Find out why</Label>
          <Switch
            checked={step.ask_why ?? true}
            checkedChildren="Yes"
            className="ml-[11px]"
            disabled={!editable}
            onChange={(checked) => { updateStep({ ...step, ask_why: checked }); }}
            unCheckedChildren="No"
          />
        </>
      );
    case StepType.NPS:
      return (
        <>
          <Input.TextArea
            autoSize={{ minRows: 1 }}
            className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
            disabled={!editable}
            onChange={(event) => { updateStep({ ...step, goal: event.target.value }); }}
            placeholder="What's the goal? (e.g. Check how likely the user is to recommend us to a friend.)"
            value={step.goal}
            variant="borderless"
          />
          <Label className="mt-4">Find out why</Label>
          <Switch
            checked={step.ask_why ?? true}
            checkedChildren="Yes"
            className="ml-[11px]"
            disabled={!editable}
            onChange={(checked) => { updateStep({ ...step, ask_why: checked }); }}
            unCheckedChildren="No"
          />
        </>
      );
    case StepType.Rating:
      return (
        <>
          <Input.TextArea
            autoSize={{ minRows: 1 }}
            className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
            disabled={!editable}
            onChange={(event) => { updateStep({ ...step, goal: event.target.value }); }}
            placeholder="What's the goal? (e.g. Ask the user to rate X.)"
            value={step.goal}
            variant="borderless"
          />
          <Label className="mt-4">Find out why</Label>
          <Switch
            checked={step.ask_why ?? true}
            checkedChildren="Yes"
            className="ml-[11px]"
            disabled={!editable}
            onChange={(checked) => { updateStep({ ...step, ask_why: checked }); }}
            unCheckedChildren="No"
          />
        </>
      );
    case StepType.FileUpload:
      return (
        <Input.TextArea
          autoSize={{ minRows: 1 }}
          className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
          disabled={!editable}
          onChange={(event) => { updateStep({ ...step, goal: event.target.value }); }}
          placeholder="What's the goal? (e.g. Ask the user to upload a resume.)"
          value={step.goal}
          variant="borderless"
        />
      );
  }
}

export default function ConfigureFlow({ assistant, updateAssistantInfo, editable }: ConfigureFlowProps): ReactElement {
  const assistantInfo = assistant.info as AssistantInfo | undefined;
  const steps = assistantInfo?.steps ?? [];

  return (
    <div className="flex flex-col gap-2">
      <Collapse
        activeKey={0}
        expandIcon={() => null}
        items={[
          {
            label: <div className="flex items-center"><LoginOutlined className="text-lg ml-[5px] mr-[15px]" />Welcome</div>,
            children: (
              <>
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  className={classNames(
                    "mb-2",
                    editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]",
                  )}
                  disabled={!editable}
                  onChange={(event) => { updateAssistantInfo({ welcomeMessage: event.target.value }); }}
                  placeholder="Welcome Message (e.g. Hi! I'm Alice, your assistant for this survey. I'll be asking you a few questions about your experience with our product. Let's get started!)"
                  value={assistantInfo?.welcomeMessage}
                  variant="borderless"
                />
                <Label className="mt-4">Start Button</Label>
                <Input
                  className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
                  disabled={!editable}
                  onChange={(event) => { updateAssistantInfo({ welcomeButtonText: event.target.value }); }}
                  placeholder="Start Button Text (e.g. Start Survey)"
                  value={assistantInfo?.welcomeButtonText}
                  variant="borderless"
                />
              </>
            ),
          },
        ]}
      />
      {steps.map((step, index) => (
        <Collapse
          activeKey={0}
          expandIcon={() => null}
          items={[
            {
              label: (
                <div className="flex items-center">
                  <StepTypeButton
                    disabled={!editable}
                    step={step}
                    updateStep={(updatedStep) => { updateAssistantInfo({
                      steps: steps.map((s, i) => i === index ? updatedStep : s),
                    }); }}
                  />
                  <Input
                    className={classNames(
                      "my-[-4px]",
                      editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]",
                    )}
                    disabled={!editable}
                    onChange={(event) => { updateAssistantInfo({
                      steps: steps.map((s, i) => i === index ? { ...s, title: event.target.value } : s),
                    }); }}
                    placeholder="Step title"
                    value={step.title}
                    variant="borderless"
                  />
                  <div className="flex-1" />
                  <Dropdown
                    disabled={!editable}
                    menu={{
                      items: [
                        {
                          label: 'Move up',
                          key: 'move-up',
                          icon: <ArrowUpOutlined />,
                          disabled: index === 0,
                          onClick: () => {
                            updateAssistantInfo({
                              steps: steps.map((s, i) => {
                                if (i === index - 1) return step;
                                if (i === index) return steps[index - 1];
                                return s;
                              }),
                            });
                          },
                        },
                        {
                          label: 'Move down',
                          key: 'move-down',
                          icon: <ArrowDownOutlined />,
                          disabled: index === steps.length - 1,
                          onClick: () => {
                            updateAssistantInfo({
                              steps: steps.map((s, i) => {
                                if (i === index) return steps[index + 1];
                                if (i === index + 1) return step;
                                return s;
                              }),
                            });
                          },
                        },
                        {
                          label: 'Delete',
                          key: 'delete',
                          icon: <DeleteOutlined />,
                          danger: true,
                          onClick: () => {
                            updateAssistantInfo({
                              steps: steps.filter((_, i) => i !== index),
                            });
                          },
                        },
                      ],
                    }}
                    trigger={['click']}
                  >
                    <Button
                      className="ml-2 my-[-5px]"
                      icon={<MoreOutlined />}
                      type="text"
                    />
                  </Dropdown>
                </div>
              ),
              children: (
                <StepContent
                  editable={editable}
                  step={step}
                  updateStep={(updatedStep) => { updateAssistantInfo({
                    steps: steps.map((s, i) => i === index ? updatedStep : s),
                  }); }}
                />
              ),
            },
          ]}
          key={index}
        />
      ))}
      {editable ? <Dropdown
        menu={{
          items: newStepMenuItems,
          onClick: ({ key }) => {
            const stepType = key as StepType;
            const step: Step = {
              ...stepTypeDefaultValues[stepType],
              type: stepType,
            };
            updateAssistantInfo({
              steps: [
                ...steps,
                step,
              ],
            });
          },
        }}
        trigger={['click']}
      >
        <Button
          className="h-[46px]"
          icon={<PlusOutlined />}
          type="dashed"
        >
          Add Step
        </Button>
      </Dropdown> : null}
      <Collapse
        activeKey={0}
        expandIcon={() => null}
        items={[
          {
            label: <div className="flex items-center"><LogoutOutlined className="text-lg ml-[5px] mr-[15px]" />End</div>,
            children: (
              <>
                <Input.TextArea
                  autoSize={{ minRows: 1 }}
                  className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
                  disabled={!editable}
                  onChange={(event) => { updateAssistantInfo({ endMessage: event.target.value }); }}
                  placeholder="End Message (e.g. Thank you for your time! Your feedback is important to us. Have a great day!)"
                  value={assistantInfo?.endMessage}
                  variant="borderless"
                />
                <Label className="mt-4">Button Text</Label>
                <Input
                  className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
                  disabled={!editable}
                  onChange={(event) => { updateAssistantInfo({ endButtonText: event.target.value }); }}
                  placeholder="Call to Action (e.g. Visit Website)"
                  value={assistantInfo?.endButtonText}
                  variant="borderless"
                />
                <Label className="mt-4">Button URL</Label>
                <Input
                  className={classNames(editable ? "focus:bg-gray-100 hover:bg-gray-100" : "!text-[rgba(0,0,0,0.88)]")}
                  disabled={!editable}
                  onChange={(event) => { updateAssistantInfo({ endButtonUrl: event.target.value }); }}
                  placeholder="Button URL (e.g. https://example.com)"
                  value={assistantInfo?.endButtonUrl}
                  variant="borderless"
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
}

import {ReactNode} from "react";
import {MultipleChoiceResult} from "../resultTypes.ts";
import {Card} from "antd";
import BarScoreChart, {Datum} from "./HelperCharts/BarScoreChart.tsx";

interface MultipleChoiceChartProps {
    options: String[],
    results: MultipleChoiceResult[]
}

function MultipleChoiceChart({
    options,
                                 results
                             }: MultipleChoiceChartProps): ReactNode {

    const multipleChoiceMap: Record<string, number> = Object.fromEntries(options.map(o => [o, 0]))

    results?.forEach(r => {
        if (multipleChoiceMap[r.answer]) {
            multipleChoiceMap[r.answer]++;
        } else {
            multipleChoiceMap[r.answer] = 1;
        }
    })

    const multipleChoiceResults = Object.keys(multipleChoiceMap).map(k => ({
        score: k.toString(),
        value: multipleChoiceMap[k]
        // todo fix missing color in types
    })) as Datum[]

    return <Card
        className="h-full overflow-hidden"
        title="Multiple choice"
    >
        <BarScoreChart
            data={multipleChoiceResults}
            colors={{"scheme": "category10"}}
            colorBy={"indexValue"}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Option",
                legendPosition: 'middle',
                legendOffset: 32
            }}
        />
    </Card>
}

export default MultipleChoiceChart
